import BannerNotification from '@viking-eng/banner-notification';
import Button from '@viking-eng/button';
import CardList from '@viking-eng/card-list';
import FlightDetailsInfo from '@viking-eng/flight-details-info';
import FlightInfoChosen from '@viking-eng/flight-info-chosen';
import Icon from '@viking-eng/icon';
import classNames from 'classnames';
import get from 'lodash/get';
import set from 'lodash/set';
import React, { useEffect, useState } from 'react';
import { history } from '../../../common';
import { triggerLinkEvent, triggerPageView } from '../../../common/Analytics';
import {
  AIR_FLOW_NAMES,
  AIR_LABELS,
  APP_PATHS,
  BAGGAGE_POLICY_URL,
  NO_DATA_PAGE,
  PRINT_BUTTON_TEXT,
  RESPONSE_STATUS,
  TAB_NAMES,
  UNKNOWN_AIRLINE,
  VIEWERSHIP_STATUSES,
} from '../../../common/Constants';
import remapCardDataBasedOnModalType from '../../../common/ModalUtils';
import { getModalType, prepareHtml, setScrollFlightDetails } from '../../../common/Utils';
import { AirPlusModal, AncillariesAndBaggageModal, ComparisonModal, SeatMapModal } from '../../../modals';
import PropTypes from '../../../PropTypes';
import NoDataPage from '../noDataPage/NoDataPage';
import './airBooking.scss';
import AirNotAvailable from './AirNotAvailableContainer';

const AirBooking = ({
  tabLabels,
  labels,
  changeClick,
  viewershipStatus,
  passengers,
  noAirPassengers,
  passengersFlights,
  abePaymentRequiredMessage,
  abePaymentRequiredBtn,
  flightBookedLabelTitle,
  onBaggagePolicy,
  ancillariesTooltipNoteTitle,
  amenitiesNotAvailable,
  airlineBookingText,
  ticketNumbersPax1,
  ticketNumbersPax2,
  ticketedAccountMessage,
  hasPaidSeatsOrDeltaMessage,
  eTicketNumberText,
  ticketedAccountTitle,
  noBookingPaymentMessage,
  noBookingPaymentText,
  noBookingPaymentBtn,
  scheduleChangeMessage,
  scheduleChangeWithOptions,
  scheduleChangeOptions,
  scheduleChangeWithoutOptions,
  isLoading,
  isLoadingFlightDetails,
  flightChangesProcessingText,
  flightInfoUpdatingText,
  updatingCheckLaterText,
  readOnlyMessage,
  airCards,
  modalData,
  handleCardDetailsClicked,
  detailsLinkText,
  incompleteGIFText,
  incompleteGIFLongText,
  incompleteGIFBtn,
  isItineraryBusiness,
  operatedByText,
  seatText,
  paidSeatText,
  seatUnavailableText,
  seatUnselectedText,
  seatPendingText,
  unavailableSeatTooltipText,
  pendingSeatTooltipText,
  airDetailsStatus,
  analyticsData,
  isViewOnly,
  isPaymentsDisabled,
  layoverText,
  cancelTimer,
  onFlightDetails,
  baggagePolicyAriaText,
  onPreviewSeats,
  preAssignedSeatsPax1,
  preAssignedSeatsPax2,
  seatMapCabins,
  allowPaidSeats,
  seatMapTimeOut,
  seatMapNotAvailable,
  seatMapDataIsLoading,
  emptySeatMapDesktop,
  emptySeatMapMobile,
  updateSeatMapDataOnSegmentSelectChange,
  previewSeatsTitle,
  tryAgainText,
  previewSeatsSubTitle,
  onClickTryAgain,
  seatTimeOutCallCount,
  previewSeatsForPaxIndex,
  paymentsDisabledBanner,
}) => {
  const [loadFlightDetails, setLoadFlightDetails] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (viewershipStatus.length > 0 && analyticsData.user_session) {
      triggerPageView(window.location.pathname, 'AIR', analyticsData);
    }
  }, [viewershipStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    cancelTimer();
  });

  const [analyticsLinkState, setAnalyticsLinkState] = useState([]);
  const linkState = [].concat(analyticsLinkState);
  const triggerLinkEventIfFirstClick = (key, linkData) => {
    if (linkState.includes(key)) {
      return false;
    }
    const newLinkState = linkState;
    newLinkState.push(key);
    setAnalyticsLinkState(newLinkState);
    triggerLinkEvent({
      ...analyticsData,
      ...linkData,
    });
    return true;
  };

  const [showFlightDetailsOne, setShowFlightDetailsOne] = useState(false);
  const [showFlightDetailsTwo, setShowFlightDetailsTwo] = useState(false);

  const togglePrintClass = (elem, isPrint, cssClass = 'hide-flight-details') => {
    if (elem) {
      elem.style.height = isPrint ? 'auto' : 0;
      if (isPrint) {
        elem.classList.remove(cssClass);
      } else {
        elem.classList.add(cssClass);
      }
    }
  };

  const onPrintClick = () => {
    const flightContainers = ['flight-details-container-1', 'flight-details-container-2'];
    flightContainers.forEach((id) => togglePrintClass(document.getElementById(id), true));
    triggerLinkEventIfFirstClick('print_tickets', {
      event_name: 'print_booking',
      page_id: 'air-booking',
    });
    const printElement = document.getElementById('print-button-air');
    printElement.style.display = 'none';
    window.print();
    const resetFormatting = () => {
      printElement.style.display = 'block';
      flightContainers.forEach((id) => togglePrintClass(document.getElementById(id), false));
    };
    window.onafterprint = resetFormatting();
  };

  const getMappedSegments = (segments, pIndex, isOutbound, i) => {
    const mappedSegments = [];
    if (segments?.length && segments[0].type !== 'label') {
      mappedSegments.push({ segment: { type: 'label', text: isOutbound ? AIR_LABELS.OUTBOUND : AIR_LABELS.RETURN } });
    }
    mappedSegments.push(
      ...segments.map((segment) => {
        set(segment, 'onPreviewSeats', () => {
          triggerLinkEventIfFirstClick('preview_seats_view', {
            event_name: 'modal_view',
            modal_name: 'preview_seats',
          });
          onPreviewSeats(segment.segmentNumber, i, pIndex);
        });
        set(segment, 'onBaggagePolicy', () => {
          triggerLinkEventIfFirstClick('amenities_baggage_policy', {
            event_name: 'modal_view',
            modal_name: 'amenities_baggage_policy',
            passenger_number: pIndex,
            segment_number: segment.segmentNumber,
          });
          if (!loadFlightDetails) {
            onFlightDetails();
            setLoadFlightDetails(true);
          }
          return onBaggagePolicy(`p-${pIndex}-segment-${segment.segmentNumber}`);
        });
        return {
          segment,
        };
      })
    );
    return mappedSegments;
  };
  const cardsData = airCards.map((card) => {
    if (card.primaryButtonUrl) {
      const modalType = getModalType(card.primaryButtonUrl);
      const onClick = () => handleCardDetailsClicked(modalType, card);
      return remapCardDataBasedOnModalType(card, {
        onPrimaryButtonClick: onClick,
        onSecondaryButtonClick: onClick,
        defaultClick: onClick,
      });
    }
    return card;
  });

  const handleClose = (i) => {
    if (i === 0) {
      setShowFlightDetailsOne(true);
    } else {
      setShowFlightDetailsTwo(true);
    }
  };

  const checkHasAnyViewerships = (viewershipStatuses) =>
    viewershipStatuses.filter((status) => viewershipStatus.includes(status)).length > 0;
  if (
    airDetailsStatus !== RESPONSE_STATUS.SUCCESS_200 &&
    airDetailsStatus !== RESPONSE_STATUS.SUCCESS_PARTIAL_CONTENT_206 &&
    !isLoading
  ) {
    return (
      <NoDataPage
        message={NO_DATA_PAGE.HOME_MESSAGE}
        actionText={NO_DATA_PAGE.HOME_CALL_TO_ACTION}
        actionLink={APP_PATHS.HOME}
      />
    );
  }
  return (viewershipStatus.includes(VIEWERSHIP_STATUSES.FLIGHT_ERROR) ||
    viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_AIR_NO_PAYMENT)) &&
    !viewershipStatus.includes(VIEWERSHIP_STATUSES.SCHED_CHANGE) ? (
    <>
      <div className="air-not-available">
        <AirNotAvailable
          readOnlyFlight={viewershipStatus.includes(VIEWERSHIP_STATUSES.READ_ONLY)}
          rightRezFlight={viewershipStatus.includes(VIEWERSHIP_STATUSES.RIGHT_REZ)}
          noVikingAir={viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_VIKING_AIR)}
          noAirNoPayment={viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_AIR_NO_PAYMENT)}
          isLoading={isLoading}
          noGIF={viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_GIF)}
          noGIFOverride={viewershipStatus.includes(VIEWERSHIP_STATUSES.GIF_OVERRIDE)}
          cruiseOnlyNoGIF={viewershipStatus.includes(VIEWERSHIP_STATUSES.CRUISE_ONLY_NO_GIF)}
        />
        {!viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_GIF) && <CardList cards={cardsData} />}
        <ComparisonModal {...modalData} id="comparisonModal" />
        <AirPlusModal data={modalData} />
      </div>
    </>
  ) : (
    <>
      {!isLoading && (
        <>
          {isPaymentsDisabled && (
            <div className="row">
              <div className="container banner-wrapper payment-alert-container">
                <BannerNotification
                  alert={{
                    alertText: paymentsDisabledBanner,
                  }}
                  icon="exclamation-mark-filled"
                />
              </div>
            </div>
          )}
          {viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_PAYMENT) && noBookingPaymentMessage && (
            <div className="air-no-booking-payment">
              <BannerNotification
                alert={{
                  alertText: noBookingPaymentMessage,
                  button: noBookingPaymentBtn,
                  alertEndText: '',
                  linkAlert: true,
                  alertType: 'air',
                }}
                icon="exclamation-mark-filled"
                history={history}
              />
              <div className="row">
                <div className="col-12 text" dangerouslySetInnerHTML={prepareHtml(noBookingPaymentText)} />
              </div>
            </div>
          )}
          {viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_GIF) &&
            !viewershipStatus.includes(VIEWERSHIP_STATUSES.GIF_OVERRIDE) &&
            incompleteGIFText &&
            incompleteGIFLongText && (
              <div className="air-no-guest-information">
                {!isViewOnly && (
                  <BannerNotification
                    alert={{
                      alertText: '',
                      button: incompleteGIFBtn,
                      alertEndText: incompleteGIFText,
                      linkAlert: true,
                      alertType: 'air',
                    }}
                    icon="exclamation-mark-filled"
                    history={history}
                  />
                )}
                <div className="row">
                  <div className="col-12 text" dangerouslySetInnerHTML={prepareHtml(incompleteGIFLongText)} />
                </div>
              </div>
            )}
          {viewershipStatus.includes(VIEWERSHIP_STATUSES.BALANCE_DUE) && abePaymentRequiredMessage && !isViewOnly && (
            <div className="balance-due-notification">
              <div className="container banner-wrapper">
                <BannerNotification
                  alert={{
                    alertText: '',
                    button: abePaymentRequiredBtn,
                    alertEndText: abePaymentRequiredMessage,
                    linkAlert: true,
                    alertType: 'air',
                  }}
                  icon="exclamation-mark-filled"
                  history={history}
                />
              </div>
            </div>
          )}
          {viewershipStatus.includes(VIEWERSHIP_STATUSES.AIR_PENDING) && flightChangesProcessingText && (
            <div className="balance-due-notification">
              <div className="container banner-wrapper">
                <BannerNotification
                  alert={{
                    alertText: flightChangesProcessingText,
                  }}
                  icon="reload"
                  history={history}
                />
              </div>
            </div>
          )}
          {viewershipStatus.includes(VIEWERSHIP_STATUSES.SCHED_CHANGE) && scheduleChangeMessage && (
            <div className="air-schedule-change">
              <BannerNotification
                alert={{
                  alertText: scheduleChangeMessage,
                }}
                icon="exclamation-mark-filled"
                history={history}
              />
              {!viewershipStatus.includes(VIEWERSHIP_STATUSES.READ_ONLY) ? (
                <div className="row">
                  <div className="col-12 text" dangerouslySetInnerHTML={prepareHtml(scheduleChangeWithOptions)} />
                  {scheduleChangeOptions && (
                    <div className="col-12 options" dangerouslySetInnerHTML={prepareHtml(scheduleChangeOptions)} />
                  )}
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 text" dangerouslySetInnerHTML={prepareHtml(scheduleChangeWithoutOptions)} />
                </div>
              )}
            </div>
          )}
          {!viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_PAX_FLIGHTS) && (
            <div className="abe-air-booking" data-id="abe-air-booking">
              <div className="row">
                <div className="col-12 pre-selected-title">
                  {!checkHasAnyViewerships([VIEWERSHIP_STATUSES.TICKETED, VIEWERSHIP_STATUSES.AIR_PENDING]) && (
                    <h5>{get(tabLabels, `${TAB_NAMES.AIR_BOOKING}.subtitle`, '')}</h5>
                  )}
                  {checkHasAnyViewerships([VIEWERSHIP_STATUSES.TICKETED, VIEWERSHIP_STATUSES.AIR_PENDING]) && (
                    <h5>{ticketedAccountTitle}</h5>
                  )}
                </div>
              </div>
              <div className="row justify-content-between align-items-center flight-booked-label-container">
                <div className="col-12 flight-booked-label">
                  {!checkHasAnyViewerships([
                    VIEWERSHIP_STATUSES.TICKETED,
                    VIEWERSHIP_STATUSES.AIR_PENDING,
                    VIEWERSHIP_STATUSES.READ_ONLY,
                    VIEWERSHIP_STATUSES.HAS_PAID_SEATS,
                    VIEWERSHIP_STATUSES.IS_DELTA_COMFORT_PLUS,
                  ]) && (
                    <div className="row align-items-center">
                      <div
                        className="col mobile-small-text"
                        dangerouslySetInnerHTML={prepareHtml(flightBookedLabelTitle)}
                      />
                    </div>
                  )}
                  {viewershipStatus.includes(VIEWERSHIP_STATUSES.AIR_PENDING) && (
                    <div className="row align-items-center">
                      <div className="col mobile-small-text">{flightInfoUpdatingText}</div>
                    </div>
                  )}
                  {viewershipStatus.includes(VIEWERSHIP_STATUSES.TICKETED) && (
                    <div className="row align-items-center">
                      <div
                        className="col mobile-small-text"
                        dangerouslySetInnerHTML={prepareHtml(ticketedAccountMessage)}
                      />
                    </div>
                  )}
                  {!viewershipStatus.includes(VIEWERSHIP_STATUSES.TICKETED) &&
                    checkHasAnyViewerships([
                      VIEWERSHIP_STATUSES.HAS_PAID_SEATS,
                      VIEWERSHIP_STATUSES.IS_DELTA_COMFORT_PLUS,
                    ]) && (
                      <div className="row align-items-center">
                        <div
                          className="col mobile-small-text"
                          dangerouslySetInnerHTML={prepareHtml(hasPaidSeatsOrDeltaMessage)}
                        />
                      </div>
                    )}
                  {viewershipStatus.includes(VIEWERSHIP_STATUSES.READ_ONLY) &&
                    !viewershipStatus.includes(VIEWERSHIP_STATUSES.AIR_PENDING) &&
                    !viewershipStatus.includes(VIEWERSHIP_STATUSES.TICKETED) && (
                      <div className="row align-items-center">
                        <div className="col mobile-small-text" dangerouslySetInnerHTML={prepareHtml(readOnlyMessage)} />
                      </div>
                    )}
                </div>
              </div>
              <div className="row air-buttons-container">
                {!checkHasAnyViewerships([VIEWERSHIP_STATUSES.READ_ONLY, VIEWERSHIP_STATUSES.RIGHT_REZ]) && (
                  <div className="col-12 col-md-4">
                    <Button
                      disabled={
                        isViewOnly ||
                        checkHasAnyViewerships([VIEWERSHIP_STATUSES.BALANCE_DUE, VIEWERSHIP_STATUSES.AIR_PENDING])
                      }
                      appearance="secondary-blue"
                      attributes={{ role: 'link' }}
                      onButtonClick={() => {
                        triggerLinkEvent(
                          {
                            ...analyticsData,
                            event_name: 'air_mod',
                            event: 'abe_submit_cs_0',
                            air_mod_type: AIR_FLOW_NAMES.CHANGE_SEATS,
                          },
                          {
                            event: 'cta_button_click',
                            cta_name: 'change_seats',
                            link_type: 'button',
                          }
                        );
                        return changeClick(AIR_FLOW_NAMES.CHANGE_SEATS);
                      }}
                      className="view-seat-btn"
                    >
                      <Icon name="airplane-seat" />
                      {get(tabLabels, `${TAB_NAMES.AIR_BOOKING}.labels.buttons.viewChangeSeats`, 'Change Seats')}
                    </Button>
                  </div>
                )}
                {!checkHasAnyViewerships([
                  VIEWERSHIP_STATUSES.READ_ONLY,
                  VIEWERSHIP_STATUSES.RIGHT_REZ,
                  VIEWERSHIP_STATUSES.TICKETED,
                  VIEWERSHIP_STATUSES.HAS_PAID_SEATS,
                  VIEWERSHIP_STATUSES.IS_DELTA_COMFORT_PLUS,
                ]) && (
                  <>
                    {!isItineraryBusiness && (
                      <div className="col-12 col-md-4">
                        <Button
                          disabled={
                            isPaymentsDisabled ||
                            isViewOnly ||
                            checkHasAnyViewerships([VIEWERSHIP_STATUSES.BALANCE_DUE, VIEWERSHIP_STATUSES.AIR_PENDING])
                          }
                          appearance="secondary-blue"
                          attributes={{ role: 'link' }}
                          onButtonClick={() => {
                            triggerLinkEvent(
                              {
                                ...analyticsData,
                                event_name: 'air_mod',
                                event: 'abe_submit_uc_0',
                                air_mod_type: AIR_FLOW_NAMES.UPGRADE_CLASS,
                              },
                              {
                                event: 'cta_button_click',
                                cta_name: 'upgrade_class',
                                link_type: 'button',
                              }
                            );
                            return changeClick(AIR_FLOW_NAMES.UPGRADE_CLASS);
                          }}
                          className="upgrade-class-btn"
                        >
                          <Icon name="upgrade-class" />
                          {get(tabLabels, `${TAB_NAMES.AIR_BOOKING}.labels.buttons.upgradeClass`, '')}
                        </Button>
                      </div>
                    )}
                    <div className="col-12 col-md-4 change-flight-btn">
                      <Button
                        disabled={
                          isPaymentsDisabled ||
                          isViewOnly ||
                          checkHasAnyViewerships([VIEWERSHIP_STATUSES.BALANCE_DUE, VIEWERSHIP_STATUSES.AIR_PENDING])
                        }
                        appearance="secondary-blue"
                        attributes={{ role: 'link' }}
                        onButtonClick={() => {
                          triggerLinkEvent(
                            {
                              ...analyticsData,
                              event_name: 'air_mod',
                              event: 'abe_submit_cf_0',
                              air_mod_type: AIR_FLOW_NAMES.CHANGE_ITINERARIES,
                            },
                            {
                              event: 'cta_button_click',
                              cta_name: 'change_flights',
                              link_type: 'button',
                            }
                          );
                          return changeClick(AIR_FLOW_NAMES.CHANGE_ITINERARIES);
                        }}
                        className="change-flight-btn"
                      >
                        <Icon name="jet" />
                        {get(tabLabels, `${TAB_NAMES.AIR_BOOKING}.labels.buttons.changeFlights`, '')}
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {passengersFlights.map((passenger, i) => {
                const nameObj = passengers.find((pax) => pax.number === passenger.passengerNumber);
                if (!nameObj) {
                  return <></>;
                }
                const departureLegs = getMappedSegments(
                  passenger.flightSchedule.pre,
                  passenger.passengerNumber,
                  true,
                  i
                );
                const arrivalLegs = getMappedSegments(
                  passenger.flightSchedule.post,
                  passenger.passengerNumber,
                  false,
                  i
                );
                const allLegsUnfiltered = departureLegs.concat(arrivalLegs);
                const allLegs = allLegsUnfiltered.filter((item) => item.segment.type !== 'label');

                return (
                  <div className="row passenger-flight" key={`key-${i + 1}`}>
                    <div className="col-12 d-flex justify-content-between">
                      <h6 className="passenger-name">{nameObj.name}</h6>
                      {i === 0 && (
                        <div id="print-button-air">
                          <Button
                            appearance="text-with-icon-left"
                            attributes={{ role: 'link' }}
                            onButtonClick={() => onPrintClick()}
                            className="print-btn"
                          >
                            <Icon name="printer" />
                            {PRINT_BUTTON_TEXT}
                          </Button>
                        </div>
                      )}
                    </div>
                    {!viewershipStatus.includes(VIEWERSHIP_STATUSES.AIR_PENDING) ? (
                      <>
                        <div className="col-12">
                          <FlightInfoChosen
                            departureLegs={departureLegs}
                            arrivalLegs={arrivalLegs}
                            onFlightDetails={() => {
                              triggerLinkEventIfFirstClick('details_baggage_fees', {
                                event_name: 'accordion_view',
                                accordion_name: 'details_baggage_fees',
                                passenger_number: passenger.passengerNumber,
                              });
                              if (i === 0 ? !showFlightDetailsOne : !showFlightDetailsTwo) {
                                setScrollFlightDetails(`flight-details-container-${i + 1}`, 48);
                              } else {
                                document
                                  .getElementById(`flight-details-container-${i + 1}`)
                                  .setAttribute('style', 'height: 0;');
                              }
                              return i === 0
                                ? setShowFlightDetailsOne(!showFlightDetailsOne)
                                : setShowFlightDetailsTwo(!showFlightDetailsTwo);
                            }}
                            departureText={get(labels, 'outbound', '')}
                            arrivalText={get(labels, 'return', '')}
                            detailsLinkText={detailsLinkText}
                            showAirlineBookingNumber
                            showFlightDetails={i === 0 ? showFlightDetailsOne : showFlightDetailsTwo}
                            errorLogoSrc={UNKNOWN_AIRLINE.logoURL}
                            operatedByText={operatedByText}
                            seatText={seatText}
                            paidSeatText={paidSeatText}
                            seatUnavailableText={seatUnavailableText}
                            seatUnselectedText={seatUnselectedText}
                            seatPendingText={seatPendingText}
                            unavailableSeatTooltipText={unavailableSeatTooltipText}
                            pendingSeatTooltipText={pendingSeatTooltipText}
                            showPaidSeatLabel
                          />
                        </div>
                        <div
                          id={`flight-details-container-${i + 1}`}
                          className={classNames('flight-details-container col-12', {
                            'hide-flight-details': i === 0 ? !showFlightDetailsOne : !showFlightDetailsTwo,
                          })}
                        >
                          <FlightDetailsInfo
                            departureLegs={departureLegs}
                            arrivalLegs={arrivalLegs}
                            showPreviewSeats
                            onClose={() => handleClose(i)}
                            flightInfoId={1}
                            baggagePolicyLinkText={get(labels, 'amenitiesAndBaggagePolicy', '')}
                            outboundStopOver={get(labels, 'outboundStopOver', '')}
                            returnStopOver={get(labels, 'returnStopOver', '')}
                            outBoundText={get(labels, 'outbound', '')}
                            returnText={get(labels, 'return', '')}
                            outboundHotelStopOver={get(labels, 'outboundHotelStopOver', '')}
                            returnHotelStopOver={get(labels, 'returnHotelStopOver', '')}
                            airlineBookingText={airlineBookingText}
                            showAirlineBookingNumber
                            ticketNumbers={i === 0 ? ticketNumbersPax1 : ticketNumbersPax2}
                            ticketText={eTicketNumberText}
                            layoverText={layoverText}
                          />
                        </div>
                        {allLegs.map((leg, index) => {
                          const preAssignedSeat1 = preAssignedSeatsPax1?.[index]?.seat || '';
                          const preAssignedSeat2 = preAssignedSeatsPax2?.[index]?.seat || '';
                          return (
                            <div
                              key={`key-${passenger.passengerNumber}-segment-${
                                leg.segment.segmentNumber || leg.segment.text
                              }`}
                            >
                              <AncillariesAndBaggageModal
                                id={`p-${passenger.passengerNumber}-segment-${
                                  leg.segment.segmentNumber || leg.segment.text
                                }`}
                                title={get(labels, 'amenitiesAndBaggagePolicy', '')}
                                flightDetails={leg.segment.flightDetails}
                                ancillariesTooltipNoteTitle={ancillariesTooltipNoteTitle}
                                amenitiesNotAvailable={amenitiesNotAvailable}
                                baggageLink={() => {
                                  triggerLinkEventIfFirstClick('baggage_policy_view', {
                                    event_name: 'baggage_policy_view',
                                    passenger_number: passenger.passengerNumber,
                                    segment_number: leg.segment.segmentNumber,
                                  });
                                  window.open(BAGGAGE_POLICY_URL, '_blank', 'noreferrer,noopener');
                                }}
                                baggageLinkText={get(labels, 'baggagePolicy', '')}
                                isFlightDetailsPending={isLoadingFlightDetails}
                                baggagePolicyAriaText={baggagePolicyAriaText}
                              />
                              <SeatMapModal
                                allFlights={allLegs}
                                key={`m-${leg.segment.segmentNumber}-p-${passenger.passengerNumber}`}
                                id={`m-${leg.segment.segmentNumber}-p-${passenger.passengerNumber}`}
                                currentFlight={leg.segment}
                                cabins={seatMapCabins}
                                preAssignedSeatsPax1={preAssignedSeatsPax1}
                                preAssignedSeatsPax2={preAssignedSeatsPax2}
                                showPreAssignedSeats
                                seatMapDataIsLoading={seatMapDataIsLoading}
                                previewSeatsTitle={previewSeatsTitle}
                                previewSeatsSubTitle={previewSeatsSubTitle}
                                seatMapNotAvailable={seatMapNotAvailable}
                                emptySeatMapDesktop={emptySeatMapDesktop}
                                emptySeatMapMobile={emptySeatMapMobile}
                                seatMapTimeOut={seatMapTimeOut}
                                tryAgainText={tryAgainText}
                                onClickTryAgain={(value) => {
                                  onClickTryAgain(value);
                                }}
                                seatTimeOutCallCount={seatTimeOutCallCount}
                                preAssignedSeat1={preAssignedSeat1}
                                preAssignedSeat2={preAssignedSeat2}
                                seatSelected1={preAssignedSeat1}
                                seatSelected2={preAssignedSeat2}
                                allowPaidSeats={allowPaidSeats}
                                showPaidSeats={false}
                                updateSeatMapDataOnSegmentSelectChange={(value) =>
                                  updateSeatMapDataOnSegmentSelectChange(value, previewSeatsForPaxIndex)
                                }
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="col-12">
                        <div className="air-pending-container">{updatingCheckLaterText}</div>
                      </div>
                    )}
                  </div>
                );
              })}
              {noAirPassengers &&
                noAirPassengers.length > 0 &&
                noAirPassengers.map(({ name, message }) => (
                  <div className="row passenger-flight no-air">
                    <div className="col-12">
                      <h6 className="passenger-name">{name}</h6>
                    </div>
                    <div className="col-12">
                      <div className="no-air-container">{message}</div>
                    </div>
                  </div>
                ))}
              <>
                <CardList cards={cardsData} />
                <ComparisonModal {...modalData} id="comparisonModal" />
                <AirPlusModal
                  data={modalData}
                  // isSubmitting={isSubmitting}
                  // isSubmittingArr={isSubmittingArr}
                />
              </>
            </div>
          )}
        </>
      )}
    </>
  );
};
AirBooking.propTypes = {
  abePaymentRequiredMessage: PropTypes.string,
  abePaymentRequiredBtn: PropTypes.shape(),
  airCards: PropTypes.arrayOf(PropTypes.any),
  airDetailsStatus: PropTypes.number,
  airlineBookingText: PropTypes.string,
  amenitiesNotAvailable: PropTypes.string,
  analyticsData: PropTypes.shape(),
  ancillariesTooltipNoteTitle: PropTypes.string,
  cancelTimer: PropTypes.func,
  changeClick: PropTypes.func,
  detailsLinkText: PropTypes.string,
  eTicketNumberText: PropTypes.string,
  flightBookedLabelTitle: PropTypes.string,
  flightChangesProcessingText: PropTypes.string,
  flightInfoUpdatingText: PropTypes.string,
  handleCardDetailsClicked: PropTypes.func,
  isItineraryBusiness: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingFlightDetails: PropTypes.bool,
  isNoBookingPayment: PropTypes.bool,
  isScheduleChange: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  labels: PropTypes.shape(),
  layoverText: PropTypes.string,
  modalData: PropTypes.shape(PropTypes.modalData),
  noAirPassengers: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, message: PropTypes.string })),
  noBookingPaymentMessage: PropTypes.string,
  noBookingPaymentText: PropTypes.string,
  noBookingPaymentBtn: PropTypes.shape(),
  incompleteGIFLongText: PropTypes.string,
  incompleteGIFText: PropTypes.string,
  incompleteGIFBtn: PropTypes.shape(),
  onBaggagePolicy: PropTypes.func,
  onFlightDetails: PropTypes.func,
  operatedByText: PropTypes.string,
  passengers: PropTypes.arrayOf(PropTypes.any),
  passengersFlights: PropTypes.arrayOf(PropTypes.any),
  pendingSeatTooltipText: PropTypes.string,
  readOnlyMessage: PropTypes.string,
  scheduleChangeMessage: PropTypes.string,
  scheduleChangeOptions: PropTypes.string,
  scheduleChangeWithOptions: PropTypes.string,
  scheduleChangeWithoutOptions: PropTypes.string,
  seatPendingText: PropTypes.string,
  seatText: PropTypes.string,
  paidSeatText: PropTypes.string,
  seatUnavailableText: PropTypes.string,
  seatUnselectedText: PropTypes.string,
  tabLabels: PropTypes.shape(),
  ticketedAccountMessage: PropTypes.string,
  ticketedAccountTitle: PropTypes.string,
  hasPaidSeatsOrDeltaMessage: PropTypes.string,
  ticketNumbersPax1: PropTypes.arrayOf(PropTypes.any),
  ticketNumbersPax2: PropTypes.arrayOf(PropTypes.any),
  unavailableSeatTooltipText: PropTypes.string,
  updatingCheckLaterText: PropTypes.string,
  viewershipStatus: PropTypes.arrayOf(PropTypes.string),
  baggagePolicyAriaText: PropTypes.string,
  onPreviewSeats: PropTypes.func,
  preAssignedSeatsPax1: PropTypes.arrayOf(PropTypes.any),
  preAssignedSeatsPax2: PropTypes.arrayOf(PropTypes.any),
  seatMapCabins: PropTypes.arrayOf(PropTypes.any),
  allowPaidSeats: PropTypes.bool,
  seatMapTimeOut: PropTypes.bool,
  seatMapNotAvailable: PropTypes.bool,
  seatMapDataIsLoading: PropTypes.bool,
  emptySeatMapDesktop: PropTypes.string,
  emptySeatMapMobile: PropTypes.string,
  updateSeatMapDataOnSegmentSelectChange: PropTypes.func,
  previewSeatsTitle: PropTypes.string,
  tryAgainText: PropTypes.string,
  previewSeatsSubTitle: PropTypes.string,
  onClickTryAgain: () => null,
  seatTimeOutCallCount: PropTypes.number,
  previewSeatsForPaxIndex: PropTypes.number,
};
AirBooking.defaultProps = {
  abePaymentRequiredMessage: '',
  abePaymentRequiredBtn: null,
  airCards: [],
  airDetailsStatus: null,
  airlineBookingText: '',
  amenitiesNotAvailable: '',
  analyticsData: null,
  ancillariesTooltipNoteTitle: '',
  cancelTimer: () => null,
  changeClick: () => null,
  detailsLinkText: '',
  eTicketNumberText: '',
  flightBookedLabelTitle: '',
  flightChangesProcessingText: '',
  flightInfoUpdatingText: '',
  handleCardDetailsClicked: null,
  isItineraryBusiness: false,
  isLoading: true,
  isLoadingFlightDetails: true,
  isNoBookingPayment: false,
  isScheduleChange: false,
  isViewOnly: false,
  labels: {},
  layoverText: '',
  modalData: {},
  noAirPassengers: [],
  noBookingPaymentMessage: '',
  noBookingPaymentText: '',
  noBookingPaymentBtn: null,
  incompleteGIFLongText: '',
  incompleteGIFText: '',
  incompleteGIFBtn: null,
  onBaggagePolicy: () => null,
  onFlightDetails: () => null,
  operatedByText: '',
  passengers: [],
  passengersFlights: [],
  pendingSeatTooltipText: '',
  readOnlyMessage: '',
  scheduleChangeMessage: '',
  scheduleChangeOptions: '',
  scheduleChangeWithOptions: '',
  scheduleChangeWithoutOptions: '',
  seatPendingText: '',
  seatText: '',
  paidSeatText: '',
  seatUnavailableText: '',
  seatUnselectedText: '',
  tabLabels: {},
  ticketedAccountMessage: '',
  ticketedAccountTitle: '',
  hasPaidSeatsOrDeltaMessage: '',
  ticketNumbersPax1: [],
  ticketNumbersPax2: [],
  unavailableSeatTooltipText: '',
  updatingCheckLaterText: '',
  viewershipStatus: [],
  baggagePolicyAriaText: '',
  onPreviewSeats: () => null,
  preAssignedSeatsPax1: [],
  preAssignedSeatsPax2: [],
  seatMapCabins: [],
  allowPaidSeats: false,
  seatMapTimeOut: false,
  seatMapNotAvailable: false,
  seatMapDataIsLoading: false,
  emptySeatMapDesktop: false,
  emptySeatMapMobile: false,
  updateSeatMapDataOnSegmentSelectChange: () => null,
  previewSeatsTitle: false,
  tryAgainText: false,
  previewSeatsSubTitle: false,
  onClickTryAgain: () => null,
  seatTimeOutCallCount: 0,
  previewSeatsForPaxIndex: 0,
};
AirBooking.displayName = 'AirBooking';

export default AirBooking;
