import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm, reset, SubmissionError } from 'redux-form';
import commonStore, { userStore } from '../../../common';
import { CREDIT_CARD, EVO_USER_TYPES, FORMS, TAB_NAMES, USER_TYPES } from '../../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../../common/ModalStore';
import { showModal } from '../../../common/Utils';
import { withContent, withLoadingGuard } from '../../../components';
import paymentsStore, {
  fetchOnboardCC,
  fetchTabContent,
  gotoNextLocationIfNeeded,
  isOnboardCCValidThroughCruise,
  mapPaymentDetailsToPayload,
  removeOnboardCreditCard,
  submitOnboardCreditCard,
} from '../PaymentsStore';
import { tokenize } from '../TokenEx';
import OnBoardCreditCard from './OnboardCreditCard';

const {
  selectors: {
    getOnboardCreditCardSection,
    getRemoveOnboardCCModal,
    isLoadingOnboardCC,
    getCreditCardFields,
    getTabContent,
    getShareCCStatus,
    getOnboardCCGuests,
    isAddingCreditCard,
    getOnboardCreditCardInitialValues,
  },
  creators: { setShareOnboardCC, updateAddCardFlag },
} = paymentsStore;
const formName = FORMS.ONBOARD_CREDIT_CARD;
const {
  selectors: { getSubmitting, getPaymentsAllEnabled, getMvjStrings },
} = commonStore;
const { clearModal } = modalStore.creators;

const { getCountryCodeFromCurrency, getUpdateUserData } = userStore.selectors;

const mapStateToProps = (state) => {
  const mvjStrings = getMvjStrings(state);
  const updateUserInfo = getUpdateUserData(state);
  const genericValidationMessage = get(mvjStrings.errors, 'genericValidationError[0].message', '');
  const restrictPaymentMessage =
    updateUserInfo?.updateUserType === EVO_USER_TYPES[USER_TYPES.CSA]
      ? get(mvjStrings.errors, 'restrictPaymentAddAccountError[0].message', '')
      : '';
  const country = formValueSelector(formName)(state, 'country') || getCountryCodeFromCurrency(state);
  const { creditCardFields } = getCreditCardFields(state)({ tabName: TAB_NAMES.ONBOARD_CREDIT_CARD, country });
  return {
    isLoading: isLoadingOnboardCC(state),
    paymentMethods: getOnboardCreditCardSection(state),
    removeOnboardCCModalData: getRemoveOnboardCCModal(state),
    isSubmitting: getSubmitting(state),
    fields: creditCardFields,
    content: getTabContent(state)(TAB_NAMES.ONBOARD_CREDIT_CARD),
    isSharingCC: getShareCCStatus(state),
    guests: getOnboardCCGuests(state),
    isAddingCard: isAddingCreditCard(state),
    initialValues: getOnboardCreditCardInitialValues(state)(country),
    isOnboardCreditCardDateInvalid: isOnboardCCValidThroughCruise(state),
    genericValidationMessage,
    country,
    disableSaveCardButton: updateUserInfo?.updateUserType === EVO_USER_TYPES[USER_TYPES.CSA],
    restrictPaymentMessage,
    isPaymentsDisabled: !getPaymentsAllEnabled(state),
    paymentsDisabledBanner: getMvjStrings(state)?.labels?.generic?.paymentsDisabled || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(fetchOnboardCC());
    dispatch(fetchTabContent(TAB_NAMES.ONBOARD_CREDIT_CARD));
  },
  openRemovalModal: (type, id) => {
    dispatch(setViewAndShowModal(type, { id }));
  },
  handleRemoveOnboardCreditCard: () => dispatch(removeOnboardCreditCard()),
  handleCancelOnboardCCRemove: () => {
    dispatch(clearModal());
  },
  resetReservationForm: () => {
    dispatch(reset(formName));
    dispatch(updateAddCardFlag(false));
  },
  handleUpdateAddCardFlag: (status) => dispatch(updateAddCardFlag(status)),
});

// eslint-disable-next-line max-len
const makePaymentCall = (payload, resolve, rejectPromise, selectedGuest, isSharingCC) => (dispatch) => {
  const promise = dispatch(submitOnboardCreditCard(payload, selectedGuest, isSharingCC))
    .then((errorMessage) => {
      if (errorMessage) {
        rejectPromise(errorMessage);
      } else {
        resolve(true);
      }
    })
    .catch((error) => {
      throw error;
    });
  return promise;
};

const onSubmit = (values, dispatch, { cardTypeError, isSharingCC }) => {
  if (cardTypeError) {
    return Promise.reject(new SubmissionError({ _error: 'genericValidationError' }));
  }

  const { nameOnCard, token, reTokenize } = values;
  const selectedGuest = parseInt(nameOnCard, 10);
  const promise = new Promise((resolve, reject) => {
    const rejectPromise = (message) => {
      reject(new SubmissionError({ _error: message }));
    };

    // eslint-disable-next-line max-len
    const submit = (payload) =>
      dispatch(makePaymentCall(payload, resolve, rejectPromise, selectedGuest, isSharingCC))
        .then((...args) => {
          dispatch(gotoNextLocationIfNeeded());
          resolve(...args);
        })
        .catch(reject);
    const ccPaymentMethod = {
      ...values,
      paymentMethodType: CREDIT_CARD,
    };
    if (ccPaymentMethod.cardType === null) {
      rejectPromise('Invalid Card Type');
      return;
    }
    if (!token || reTokenize) {
      dispatch(tokenize(CREDIT_CARD)).then((tokenExData) => {
        if (!tokenExData) {
          rejectPromise('Failed to generate token');
          return;
        }

        const payload = mapPaymentDetailsToPayload(ccPaymentMethod, tokenExData.token);
        submit(payload);
      });
    } else {
      const payload = mapPaymentDetailsToPayload(ccPaymentMethod, token);
      submit(payload);
    }
  });
  return promise.catch(() => {
    showModal('failedNotificationModal');
  });
};

const onChange = (values, dispatch, props) => {
  const { nameOnCard, save } = values;
  if (props.dirty && nameOnCard) {
    dispatch(setShareOnboardCC(!!save));
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContent,
  reduxForm({
    form: formName,
    onSubmit,
    onChange,
    enableReinitialize: true,
  }),
  withLoadingGuard
);

export default enhance(OnBoardCreditCard);
