const env = process.env.NODE_ENV === 'development' ? process.env : window && window.env;
// prettier-ignore
export const API_HOST = env.REACT_APP_API_HOST || 'https://mvj-develop.services.viking.com/v1';

export const APP_TITLE = env.REACT_APP_TITLE || 'My Viking Journey';
export const STATIC_API_HOST = env.REACT_APP_STATIC_API_HOST || 'https://cms-dev.services.viking.com';
export const BASE_IMAGE_URL = 'https://cms-assets.viking.com';
export const VIKING_LOGO_COLOR = `${BASE_IMAGE_URL}/logo/VIKING_COLOR.svg`;
export const VIKING_LOGO_WHITE = `${BASE_IMAGE_URL}/site_elements/viking-white-horiz-logo.svg`;
export const BROKEN_IMAGE_FALLBACK_ROOT = `${env.REACT_APP_S3_HOST || `${BASE_IMAGE_URL}/`}logo/`;
export const ENVIRONMENT_CODE = env.REACT_APP_ENVIRONMENT_CODE || 'dev';
export const SORT_UK_COUNTRY = env.REACT_APP_SORT_UK_COUNTRY || false;
export const PMT_REC_COUNT = env.REACT_APP_PMT_REC_COUNT || false;

// Images
export const IMAGE_HANDLER_BUCKET = env.REACT_APP_IMAGE_HANDLER_BUCKET;
export const IMAGE_HANDLER_BUCKET_ERROR = env.REACT_APP_IMAGE_HANDLER_BUCKET_ERROR;
export const IMAGE_HANDLER_BASE_URL = env.REACT_APP_IMAGE_HANDLER_BASE_URL;
export const NO_IMAGE = '/no-image.jpg';
export const FALLBACK_IMAGE = env.REACT_APP_FALLBACK_IMAGE || `${BASE_IMAGE_URL}/site_elements/viking_error_image.png`;
export const FALLBACK_IMAGE_SVG =
  env.REACT_APP_FALLBACK_IMAGE_SVG || `${BASE_IMAGE_URL}/site_elements/vikinglogogray.svg`;
export const FALLBACK_IMAGE_CSS_CLASS = 'img-error';
export const IMAGE_CMS_ASSET_BASE_URL = env.REACT_APP_IMAGE_CMS_ASSET_BASE_URL;
export const CC_EXPIRATION_YEARS = env.REACT_APP_CC_EXPIRATION_YEARS || 10;

// Azure Application Insights
export const AZURE_INSTRUMENTATION_KEY =
  env.REACT_APP_AZURE_INSTRUMENTATION_KEY || '8ae5839d-d909-4c5b-9c9d-4f360deb63c4';

export const COMFORT_CHECK_IN_WINDOW = Number(env.REACT_APP_COMFORT_CHECK_IN_WINDOW) || 30;

export const PATH_DIRECTORY = '/myjourney';

export const MARKETING_SITE_URLS = {
  UK: 'https://www.vikingcruises.co.uk/',
  AU: 'https://www.vikingcruises.com.au/',
  CA: 'https://www.vikingcruisescanada.com/',
  US: 'https://www.vikingcruises.com/',
};

export const TA_PORTAL_URL = env.REACT_APP_TAP_URL || `${window.location.origin}/travel-advisor`;

export const TA_DISABLED_MENU_ITEM = Object.freeze({
  ADD_BOOKING: '#addBookingModal',
});

// Thumbnail Types (aka aspect ratios)
export const ORIGINAL = 'original';
export const FIVE_BY_FOUR = '5x4';
export const FIVE_BY_TWO = '5x2';
export const THREE_BY_TWO = '3x2';
export const TWO_BY_ONE = '2x1';
export const FOUR_BY_ONE = '4x1';
export const ONE_BY_ONE = '1x1';

export const AIR_WAITER_NAMESPACES = {
  GET_AIRPORTS: 'getAirports',
  GET_AIR_SCHEDULES: 'getAirSchedules',
  GET_REBOOKING: 'getRebooking',
  GET_SEARCH_FLIGHTS: 'getSearchFlights',
  GET_SEARCH_REQS: 'getSearchReqs',
  POST_CHECKOUT: 'postCheckout',
  POST_FLIGHT_DETAILS: 'postFlightDetails',
  POST_SEARCH_FLIGHTS: 'postSearchFlights',
  SEATMAP: 'seatmap',
  VALIDATE_CHECKOUT: 'postValidateCheckout',
  POST_UPDATE_BOOKING: 'postUpdateBooking',
  POST_SAVE_SEATS: 'postSaveSeats',
  GET_SAVE_SEATS: 'getSaveSeats',
  GET_AIR_PENDING_STATUS: 'getAirPendingStatus',
  REBOOKING_STATUS: 'rebookingStatus',
};

export const WAITER_NAMESPACES = {
  ...AIR_WAITER_NAMESPACES,
};

// Modals
export const MODALS = {
  AIR_PLUS: 'airPlusModal',
  DIALOG: 'dialogModal',
  DETAILS: 'detailsModal',
  RESERVATION: 'reserveModal',
  SHOREX: 'shorexModal',
  ADD_BOOKING: 'addBookingModal',
  RESERVATION_MODAL: 'reservation',
  INVALID_FLIGHTS_MODAL: 'invalidFlightsModal',
  FAILED_BOOKING_MODAL: 'failedBookingModal',
  REPRICED_FLIGHTS_MODAL: 'repricedFlightsModal',
  SEAT_SELECTION_MODAL: 'seatSelectionModal',
  AIR_LOADING: 'airLoading',
  NO_AIR_SELECTION: 'noAirSelection',
  CLOSE_TO_SAILING: 'closeToSailingModal',
  VIEW_ONLY: 'viewOnlyModal',
  AIR_SEARCH_SESSION_EXPIRED: 'airSearchSessionExpired',
  AIR_SEARCH_SESSION_WARNING: 'airSearchSessionWarning',
  REQUEST_INVOICE_MODAL: 'requestInvoiceModal',
  PRE_SELECTED_CHOSEN_MODAL: 'preSelectedChosenModal',
  PHOTO_UPLOAD_MODAL: 'photoUploadModal',
  TOKEN_ERROR: 'tokenError',
  NO_SEAT_SELECTED_MODAL: 'noSeatSelectedModal',
  EXIT_SEAT_SELECTED_MODAL: 'exitSeatSelectedModal',
  CONTACT_US: 'contactUs-modal',
  AIR_LEAVE_PAGE: 'airLeavePage',
  AIR_START_OVER: 'airStartOver',
  NO_PNR: 'noPNRFoundModal',
  SEATS_PENDING: 'seatsPending',
  DECK_PLANS: 'DeckPlansModal',
  SHIP_FEATURES: 'ShipFeaturesModal',
  ADDITIONAL_INFO: 'AdditionalInfoModal',
  PHONE_SERVICES: 'PhoneServicesModal',
  STATEROOM_SERVICES: 'StateroomServicesModal',
  ELECTRICAL_OUTLETS: 'ElectricalOutletsModal',
  PAYMENT_CHALLENGE: 'PaymentChallengeModal',
  CHALLENGE_TIMEOUT: 'ChallengeTimeoutModal',
  CART_SOLD_OUT: 'cartSoldOutModal',
};

export const MODAL_CHARS_IN_ONE_LINE = 80;

export const MODAL_BUTTONS = {
  CANCEL: 'cancel',
  CONFIRM: 'confirm',
};

// Forms
export const FORMS = {
  ALLERGIES: 'allergies',
  AIR: 'air',
  BOOKING: 'booking',
  FORGOT_PASSWORD: 'forgotPassword',
  GUEST_INFO_FORM: 'guestInfoForm',
  MANAGE_PAYMENT_METHOD: 'managePaymentMethod',
  ONBOARD_CREDIT_CARD: 'onboardCreditCard',
  PAYMENT_CHECKOUT: 'paymentCheckout',
  RESERVATION: 'reservation',
  SPECIAL_OCCASIONS: 'specialOccasions',
  STATEROOM_PREFERENCES: 'stateroomPreferences',
  USER_PROFILE: 'userProfile',
  REQUEST_INVOICE: 'requestInvoice',
  LOGIN: 'login',
  CREATE_ACCOUNT: 'createAccount',
  FORGOT_EMAIL: 'forgotEmail',
  RESET_PASSWORD: 'resetPassword',
  PTC: 'passengerTicketContract',
  ADD_BOOKING: 'addBooking',
  AIR_SEARCH: 'airSearch',
  AIR_FREQUENT_FLYER: 'airFrequentFlyer',
  AIR_PAYMENT_CHECKOUT: 'airPaymentCheckout',
  GUEST_CHECK_IN_FORM: 'guestCheckInForm',
  BOARDING_PASS: 'boardingPass',
  HEALTH_SURVEY: 'healthSurvey',
  ACTIVITIES_PASSENGER_SELECTOR: 'activitiesPassengerSelector',
  FAQ_SEARCH: 'faqSearch',
};

export const FORM_FIELD_NAMES = {
  STATE: 'State',
  ZIP: 'Zip Code',
};

export const PAYMENT_FORMS = {
  CREDIT_CARD_INFORMATION: 'Credit Card Information',
};

// APP_PATHS is for the browser routing for FE pages
export const APP_PATHS = {
  INDEX: `${PATH_DIRECTORY}/`,
  HOME: `${PATH_DIRECTORY}/home`,
  ABE_AIR: `${PATH_DIRECTORY}/air`,
  ACCOUNT_CREATED: `${PATH_DIRECTORY}/account-created`,
  ACCOUNT_PROFILE: `${PATH_DIRECTORY}/account/profile`,
  ACCOUNT: `${PATH_DIRECTORY}/account`,
  AIR_PREFERENCES: `${PATH_DIRECTORY}/air/preferences`,
  AIR: `${PATH_DIRECTORY}/air`,
  BEFORE_YOU_GO: `${PATH_DIRECTORY}/before-you-go`,
  BOOK_YOUR_NEXT_VOYAGE: `${PATH_DIRECTORY}/book-your-next-voyage`,
  CART: `${PATH_DIRECTORY}/payments/add-ons`,
  COMFORT_CHECK_IN_FORM: `${PATH_DIRECTORY}/documents/comfort-check-in/check-in`,
  COMFORT_CHECK_IN: `${PATH_DIRECTORY}/documents/comfort-check-in`,
  CREATE_ACCOUNT: `${PATH_DIRECTORY}/create-account`,
  CRUISE_PAYMENTS: `${PATH_DIRECTORY}/payments/cruise-payments`,
  CURRENTLY_UNAVAILABLE: `${PATH_DIRECTORY}/unavailable`,
  DOCUMENTS: `${PATH_DIRECTORY}/documents`,
  EMAIL_EXPIRED: `${PATH_DIRECTORY}/email-expired`,
  EXTENSIONS: `${PATH_DIRECTORY}/extensions`,
  FORGOT_EMAIL_RECOVERED: `${PATH_DIRECTORY}/forgot-email/recovered`,
  FORGOT_EMAIL: `${PATH_DIRECTORY}/forgot-email`,
  FORGOT_PASSWORD_EXPIRED: `${PATH_DIRECTORY}/forgot-password/expired`,
  FORGOT_PASSWORD_SENT: `${PATH_DIRECTORY}/forgot-password/sent`,
  FORGOT_PASSWORD: `${PATH_DIRECTORY}/forgot-password`,
  GIF: `${PATH_DIRECTORY}/documents/guest-information`,
  HEALTH_SURVEY_CHECK_IN_FORM: `${PATH_DIRECTORY}/documents/health-survey/check-in`,
  HEALTH_SURVEY_CHECK_IN: `${PATH_DIRECTORY}/documents/health-survey`,
  HELP_AIR: `${PATH_DIRECTORY}/help/air`,
  HELP_CATEGORY: `${PATH_DIRECTORY}/help/:category?`,
  HELP_FOREIGN_CURRENCY: `${PATH_DIRECTORY}/help/foreign-currency`,
  HELP_LIFE_ON_BOARD: `${PATH_DIRECTORY}/help/life-on-board`,
  HELP_MY_SHIP: `${PATH_DIRECTORY}/help/my-ship`,
  HELP_TOP_10: `${PATH_DIRECTORY}/help/top-10-questions`,
  HELP_TRIP_DOCUMENTS: `${PATH_DIRECTORY}/help/my-trip-documents`,
  HELP: `${PATH_DIRECTORY}/help`,
  ITINERARY: `${PATH_DIRECTORY}/calendar`,
  LEGACY_ABE_AIR: `${PATH_DIRECTORY}/abe/air`,
  LOGIN: `${PATH_DIRECTORY}/login`,
  LOGIN_FAQS: `${PATH_DIRECTORY}/login-faqs`,
  MAINTENANCE: `${PATH_DIRECTORY}/maintenance-page`,
  MENU: `${PATH_DIRECTORY}/menu`,
  NOTIFICATIONS_CART: `${PATH_DIRECTORY}/notifications/cart`,
  NOTIFICATIONS: `${PATH_DIRECTORY}/notifications`,
  ONBOARD_CREDIT_CARD: `${PATH_DIRECTORY}/payments/onboard-cc`,
  ONBOARD_EXPERIENCE_PREFERENCES: `${PATH_DIRECTORY}/onboard-experience/preferences`,
  ONBOARD_EXPERIENCE_STATEROOM: `${PATH_DIRECTORY}/onboard-experience/preferences/stateroom-preferences`,
  ONBOARD_EXPERIENCE: `${PATH_DIRECTORY}/onboard-experience`,
  OOPS_PAGE: `${PATH_DIRECTORY}/error`,
  PAYMENT_METHODS: `${PATH_DIRECTORY}/payments/payment-methods`,
  PAYMENTS: `${PATH_DIRECTORY}/payments`,
  RESET_PASSWORD_SUCCESS: '/myjourney/password-reset',
  RESET_PASSWORD: `${PATH_DIRECTORY}/reset-password/:emailValue/:codeValue`,
  SCHEDULE_PAYMENT: `${PATH_DIRECTORY}/schedulePayment`,
  SHORE_EXCURSIONS: `${PATH_DIRECTORY}/shore-excursions`,
  SIGN_OUT: `${PATH_DIRECTORY}/sign-out`,
  SIGN_UP_PAGE: `${PATH_DIRECTORY}/sign-up`,
  SIGN_UP: `${PATH_DIRECTORY}/sign-up/:emailValue?/:codeValue?`,
  SUBMARINE_VIDEO: `${PATH_DIRECTORY}/submarine-video`,
  SUPPORT_RESET: `${PATH_DIRECTORY}/support/reset`,
  SYSTEM_MAINTENANCE: `${PATH_DIRECTORY}/system-maintenance`,
  TRAVEL_BOOKING: `${PATH_DIRECTORY}/travel-booking`,
  TRAVEL_REQUIREMENTS: `${PATH_DIRECTORY}/travel-requirements`,
  VERIFY_EMAIL: `${PATH_DIRECTORY}/verify-email`,
  VOYAGE_NOT_AVAILABLE: `${PATH_DIRECTORY}/voyageNotAvailable`,
};

export const AIR_PATHS = {
  AIR_BOOKING: `${APP_PATHS.ABE_AIR}/air-booking`,
  CHECKOUT: `${APP_PATHS.ABE_AIR}/checkout`,
  DONE: `${APP_PATHS.ABE_AIR}/checkout-response`,
  HOME: APP_PATHS.ABE_AIR,
  REVIEW: `${APP_PATHS.ABE_AIR}/review`,
  SEARCH: `${APP_PATHS.ABE_AIR}/search`,
  SEATS: `${APP_PATHS.ABE_AIR}/seats`,
  SELECT_PASSENGERS: `${APP_PATHS.ABE_AIR}/select-passengers`,
};

export const REDIRECT_APP_PATHS = {
  GIF: '/gif',
  PATH_GIF: `${PATH_DIRECTORY}/gif`,
  VERIFLY: `${PATH_DIRECTORY}/before-you-go/verifly`,
};

export const VOYAGE_STATUSES = {
  SAILING: `${PATH_DIRECTORY}/voyage-sailing`,
  SAILED: `${PATH_DIRECTORY}/voyage-sailed`,
  CANCELED: `${PATH_DIRECTORY}/booking-cancelled`,
  COMING_SOON: `${PATH_DIRECTORY}/coming-soon`,
  FUTURE: `${PATH_DIRECTORY}/`,
  NO_BOOKINGS: `${PATH_DIRECTORY}/no-active-booking`,
  VOYAGE_LOCKED: `${PATH_DIRECTORY}/unavailable`,
};

// Valid page routes to render a component
export const VALID_PAGE_ROUTES = {
  HERO: Object.values(VOYAGE_STATUSES).filter((route) => route !== VOYAGE_STATUSES.VOYAGE_LOCKED),
};

// TAB_PATHS is for the urls to MT to get tab data
export const TAB_PATHS = {
  paymentsCart: {
    addons: 'add-ons',
    cruisePayments: 'cruise-payments',
    paymentMethods: 'payment-methods',
    checkoutCart: 'checkout-cart',
    checkoutBooking: 'checkout-booking',
    onboardCreditCard: 'onboard-cc',
  },
  onboardExperience: {
    diningBeverage: 'dining-beverage',
    enrichment: 'enrichment',
    preferences: 'preferences',
    shipCrew: 'ship-crew',
    spa: 'spa',
    stateroom: 'stateroom',
    stateroomPreferences: 'stateroom-preferences',
    foodAllergies: 'allergies',
    specialOccasions: 'special-occasions',
  },
  air: {
    airBooking: 'air-booking',
    preferences: 'preferences',
    transfers: 'transfers',
  },
  documents: {
    guestInformation: 'guest-information',
    comfortCheckIn: 'comfort-check-in',
    healthSurvey: 'health-survey',
  },
};

export const FAQ_CATEGORIES = {
  TOP_QUESTIONS: 'Top Questions',
  HEALTH_SAFETY: 'Health & Safety',
  AIR: 'Air',
  PAYMENTS_URL: '/payments/cruise-payments',
};

export const FAQ_CATEGORIES_LOGIN = {
  PASSWORD_HELP: { title: 'Password Help', order: 3 },
  SIGNING_IN: { title: 'Signing In', order: 1 },
  EMAIL_HELP: { title: 'Email Help', order: 4 },
  CREATE_ACCOUNT: { title: 'Creating Your Account', order: 2 },
  ACCOUNT_MANAGEMENT: { title: 'Account Management', order: 5, showInFAQs: true },
};

export const AUTH_STATES = {
  UNKNOWN: 'unknown',
  SIGNED_IN: 'signedIn',
  SIGNED_OUT: 'signedOut',
};

export const USER_TYPES = {
  CONSUMER: 'Consumer',
  CSA: 'CSA',
  AIR: 'AIR',
  TA: 'TA',
};

export const EVO_USER_TYPES = {
  CSA: 'ResAgent',
  TA: 'TravelAgent',
  Consumer: 'Consumer',
};

export const EVO_ADVISORY_CODES = {
  PARTY_SIZE_RES_UNAVAIL: '2310',
  NO_NON_SHARE_TABLES_AVAIL: '2318',
  DINING_NOT_OPEN: '2302',
};

export const GDS_CODES = {
  CONSUMER: 'CS',
  TA: 'TC',
};

export const ADD_BOOKING_STEPS = {
  ADD: 'add',
  PTC: 'ptc',
  SUCCESS: 'success',
};

export const REQUEST_INVOICE_STEPS = {
  ADD: 'add',
  SUCCESS: 'success',
};

export const CCI_STEPS = {
  PERSONAL_INFO: 0,
  CRUISE_INFO: 1,
  ONBOARD_CREDIT_CARD: 2,
};

export const PHOTO_UPLOAD_CROP_SIZE = {
  WIDTH: 150,
  HEIGHT: 175,
};

export const PHOTO_UPLOAD_STEPS = {
  GUIDELINE: 'guideline',
  UPLOAD: 'upload',
  CROP: 'crop',
  SUCCESS: 'success',
};

export const PHOTO_UPLOAD_MAX_SIZE = 15728640; // 15 MB

export const PHOTO_UPLOAD_CONVERT_WIDTH = 1024;

export const PHOTO_UPLOAD_CONVERT_TYPE = 'jpg';

export const PHOTO_UPLOAD_EXTENSIONS = Object.freeze(['.gif', '.heic', '.jpeg', '.jpg', '.png', '.webp']);

export const PHOTO_UPLOAD_MIME_TYPES = Object.freeze([
  'image/gif',
  'image/heic',
  'image/jpeg',
  'image/png',
  'image/webp',
]);

export const PHOTO_UPLOAD_ZOOM_DEFAULT = 1;

export const PHOTO_UPLOAD_ZOOM_MAX = 2;

export const PHOTO_UPLOAD_ZOOM_MIN = 0.1;

export const BOOKING_STATUSES = {
  FINAL: 'FNL',
  CONFIRMED: 'CNF',
  OFFER: 'OFR',
};

export const FLIGHT_INFO_STATUS = {
  UNAVAILABLE: 'Unavailable',
  SOLD_OUT: 'Sold Out',
  YOUR_CURRENT_FLIGHT: 'Your\nCurrent\nFlight',
};

export const START_GUIDE_BUTTONS = {
  TITLE: 'Quick Start Guide',
  SKIP: 'SKIP',
  CONTINUE: 'CONTINUE',
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
  START: 'START',
};

export const START_GUIDE_REFERENCES = {
  MAKE_PAYMENTS: 'mvjStartGuideMakePayments',
  MAKE_PAYMENTS_TA: 'mvjStartGuideMakePaymentsTa',
  BOOK_EXCURSIONS: 'mvjStartGuideBookExcursions',
  PRE_CRUISE_DOCUMENTS: 'mvjStartGuidePrecruiseDocuments',
  MANAGE_AIR_ITINERARY: 'mvjStartGuideManageAirItinerary',
  WELCOME_TO_MVJ: 'mvjStartGuideWelcomeToMVJ',
  THANK_YOU: 'mvjStartGuideThankYou',
};

export const FLIGHT_INFO_SCARCITY = 'Only {NUMBER_SEATS_REMAINING} left';

export const VALID_BOOKING_STATUS = ['FINAL', 'CONFIRMED', 'OPTION'];

export const BOOKING_STATUS_FOR_PURCHASE = ['CNF', 'FNL'];

export const SIGN_UP_MESSAGES = {
  CUTOVER: 'cutOver',
  SIGN_UP: 'signUp',
};

// Countries
export const COUNTRIES = {
  AUSTRALIA: 'AU',
  CANADA: 'CA',
  UNITED_KINGDOM: 'UK',
  UNITED_KINGDOM_GB: 'GB',
  UNITED_STATES: 'US',
  NEW_ZEALAND: 'NZ',
};

export const COUNTRIES_NATIONALITY = {
  AUSTRALIA: 'AUS',
  CANADA: 'CAN',
  UNITED_KINGDOM: 'GBR',
  UNITED_STATES: 'USA',
};

export const COUNTRY_TO_OFFICE = {
  US: 'US',
  CA: 'US',
  GB: 'UK',
  UK: 'UK',
  AU: 'AU',
  NZ: 'AU',
};

export const CURRENCIES = {
  US_DOLLAR: 'USD',
  CA_DOLLAR: 'CAD',
  AU_DOLLAR: 'AUD',
  GB_POUND: 'GBP',
};

export const CURRENCY_COUNTRY = {
  USD: COUNTRIES.UNITED_STATES,
  CAD: COUNTRIES.CANADA,
  AUD: COUNTRIES.AUSTRALIA,
  GBP: COUNTRIES.UNITED_KINGDOM,
};

export const FIND_COUNTRY_MAPPING = {
  UK: COUNTRIES.UNITED_KINGDOM_GB,
};

export const CURRENCY_SYMBOLS = {
  USD: '$',
  CAD: '$',
  AUD: '$',
  GBP: '£',
};

export const CURRENCY_BY_COUNTRY = {
  US: { code: 'USD', symbol: CURRENCY_SYMBOLS.USD },
  CA: { code: 'CAD', symbol: CURRENCY_SYMBOLS.CAD },
  AU: { code: 'AUD', symbol: CURRENCY_SYMBOLS.AUD },
  UK: { code: 'GBP', symbol: CURRENCY_SYMBOLS.GBP },
};

export const TIME_ZONES = {
  AU: 'Sydney Time',
  CA: 'Pacific Time',
  UK: 'Western European Time',
  US: 'Pacific Time',
};

export const TIME_ZONE_ABBREVIATIONS = {
  AU: 'AEST',
  CA: 'PT',
  UK: 'GMT',
  US: 'PT',
};

// Date formats
export const DEFAULT_DATE_FORMAT = {
  EU: 'DDMMYYYY',
  NA: 'MMDDYYYY',
  EU_SLASH: 'DD/MM/YYYY',
  NA_SLASH: 'MM/DD/YYYY',
  YEAR_FIRST: 'YYYYMMDD',
};

export const REGIONAL_LONG_DATES = {
  NA: 'MMMM D, YYYY',
  EU: 'D MMMM, YYYY',
};

export const REGIONAL_SHORT_DATES = {
  NA: 'MMMM D',
  EU: 'D MMMM',
};

export const DB_DATE_FORMAT = {
  LONG: 'YYYY-MM-DDTHH:mm:ss',
  SHORT: 'YYYY-MM-DD',
};

export const LONG_DATE_FORMAT = {
  US: REGIONAL_LONG_DATES.NA,
  CA: REGIONAL_LONG_DATES.NA,
  GB: REGIONAL_LONG_DATES.EU,
  UK: REGIONAL_LONG_DATES.EU,
  AU: REGIONAL_LONG_DATES.EU,
};

const REGIONAL_SHORT_DATES_MOMENT = {
  NA: 'MMM d, yyyy',
  EU: 'd MMM, yyyy',
};

export const SHORT_DATE_FORMAT_MOMENT = {
  US: REGIONAL_SHORT_DATES_MOMENT.NA,
  CA: REGIONAL_SHORT_DATES_MOMENT.NA,
  GB: REGIONAL_SHORT_DATES_MOMENT.EU,
  UK: REGIONAL_SHORT_DATES_MOMENT.EU,
  AU: REGIONAL_SHORT_DATES_MOMENT.EU,
};

// Payment methods
export const ACH = 'ach';
export const CREDIT_CARD = 'cc';
export const DEBIT_CARD = 'dc';
export const ADD_NEW_ACCOUNT = 'ADD NEW CC/ACH';

// Booking types
export const BOOKING_TYPE = {
  DIRECT: 'C',
  TA: 'A',
};

// Checkout types
export const ADDONS_CHECKOUT = 'addons';
export const CRUISE_CHECKOUT = 'cruise';
export const AIR_CHECKOUT = 'air';
export const SCHEDULE_PAYMENT = 'schedulePayment';

// No-selection select option
export const NONE = 'None';

// Modal Reservation types
export const RESERVATION_TYPES = {
  CART: 'cart',
  DINING: 'dining',
  SHOREX: 'shorex',
  SPA: 'spa',
  PPG: 'ppg',
};

// Device types
export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const TABLET = 'tablet';

// TODO: Put these in a REPLACEMENT_VARS constant and export just that
// string replacement variables
export const PHONE = 'PHONE';
export const PHONE1 = 'PHONE1';
export const PHONE2 = 'PHONE2';
export const AIRPHONE = 'AIRPHONE';
export const AIRPHONE1 = 'AIRPHONE1';
export const AIRPHONE2 = 'AIRPHONE2';
export const BOOKED_SEATS_NUM = 'bookedSeat';
export const TOTAL_SEATS_NUM = 'totalSeats';
export const INVITEE_BOOKINGS = 'INVITEE_BOOKINGS';

export const ALERT_TYPES = {
  ERROR: 'error',
  INFO: 'info',
};

// reservation statuses
export const RESERVATION_STATUS = {
  IN_CART: 'IN CART',
  OPEN: 'OPEN',
  RESERVED: 'RESERVED',
};

export const AIR_PLUS_RESERVATION_STATES = {
  NONE: 'None',
  REQUESTED: 'Requested',
  CONFIRMED: 'Confirmed',
};

export const CALENDAR_MODAL_STATES = {
  LOADING: 'loading',
  ERROR: 'error',
  ERROR_RESERVATION: 'errorReservation',
};

export const CALENDAR_PORT_LABELS = {
  ARRIVAL: 'Port Arrival',
  DEPARTURE: 'Departure',
};

export const CHINA_SHIP_CODE = 'CYI';

export const AIR_PLUS_REFERENCE = 'AIRPLR';

export const MINS_TO_MILLIS = 60000;

export const MVJ_LOCK_TIMER = 13 * MINS_TO_MILLIS; // Re-lock every 13 min

export const TOKENEX_SESSION_MIN_REFRESH = 15 * MINS_TO_MILLIS; // do not refresh tokenEx params before 15 mins have passed (tokenEx session = 20 mins)

export const AIR_SESSION_WARN_BEFORE_EXPIRE_MINUTES = 5; // warn when this many minutes remaining
export const AIR_SESSION_EXPIRE_MINUTES = 30; // expire after this many minutes pass

export const AIR_SESSION_WARNING_DURATION = AIR_SESSION_WARN_BEFORE_EXPIRE_MINUTES * MINS_TO_MILLIS;
export const AIR_SESSION_EXPIRE_DURATION = AIR_SESSION_EXPIRE_MINUTES * MINS_TO_MILLIS;

export const DAYS_BEFORE_AIR_AVAILABLE_VK = 90; // TODO: Post beta launch change back to 110
export const DAYS_BEFORE_AIR_AVAILABLE_RR = 90;
export const AIR_BOOKING_ENGINE = 'VK';

export const ACH_UNAVAILABLE_DAYS = 30;

export const INCLUDED_STATEROOM = {
  EXPLORER_SUITE: 'ES',
  VERANDA_SUITE: 'AA',
};

export const AIR_CARDS = {
  AIR_PLUS_CARD_REFERENCE: AIR_PLUS_REFERENCE,
  VIKING_AIR_CARD_REFERENCE: 'vikingAir',
  CLASS_UPGRADE_CARD_REFERENCE: 'Class-Upgrade',
};

// Possible passenger reservation status combinations
export const PASSENGERS_RESERVATION_STATUS = {
  EVERY_IN_CART: 'EVERY_IN_CART',
  EVERY_OPEN: 'EVERY_OPEN',
  EVERY_RESERVED: 'EVERY_RESERVED',
  SOME_IN_CART: 'SOME_IN_CART',
  SOME_OPEN: 'SOME_OPEN',
  SOME_RESERVED: 'SOME_RESERVED',
};

export const SHOREX_ACTION_TYPES = {
  EDIT: 'edit',
  UPDATE: 'update',
  CANCEL: 'cancel',
  CANCEL_NO: 'cancelNo',
  CANCEL_YES: 'cancelYes',
  CHANGE: 'change',
  CHECKOUT: 'checkout',
  CLOSE: 'close',
  OPEN: 'open',
  REMOVE: 'remove',
};

export const SHOREX_MODAL_ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

export const RESERVATION_STATE_KEYS = {
  CANCELED: 'canceled',
  CANCELING: 'canceling',
  IN_CART: 'inCart',
  OPEN: 'open',
  RESERVING: 'dining/reserving',
  RESERVING_SHARED: 'dining/reserving-shared',
  INVITING: 'dining/inviting',
  RESERVED: 'reserved',
  EDIT: 'edit',
  EDITING: 'editing',
};

export const EXTENSION_TYPES = {
  DINE: 'dine',
  SHOREX: 'S',
  GRATITUDE_OR_SSBP: 'G', // SSBP & PPG share value 'G'
  AIR_PLUS: 'X',
  SPA: 'spa',
  HOTEL: 'H',
  LAND_PACKAGE: 'E',
};

export const SERVICE_CODES = {
  SSBP: 'SSBP',
  PPG: 'GRAT',
};

export const ALERT_CODES = {
  SSBP: 'SSBP',
  AIRPEN: 'AIRPEN',
  DEPOSIT_DUE: 'DEPDUE',
  EXCOPN: 'EXCOPN',
};

export const NOTIFICATION_TYPES = {
  FAILURE: 'failure',
  PAYMENT_FAILURE: 'paymentFailure',
  UPDATE_FLIGHT_IN_PROGRESS: 'updateFlightInProgress',
  TPP_CHANGE: 'tppChange',
  SUCCESS: 'success',
};

export const STATUS_TYPES = {
  ERROR: 'error',
  MESSAGE: 'message',
};

export const PAYMENT_ACTION_TYPE = {
  CC_EDIT: 'cc_edit',
  ACH_EDIT: 'ach_edit',
  REMOVE: 'remove',
};

// Possible package types on booking
export const BOOKING_PACKAGE_TYPE = {
  PACKAGE: 'P',
  CRUISE_ONLY: 'C',
};

// Trip additions
export const ADDITIONS_TYPE = {
  PRE: 'pre',
  POST: 'post',
};

// Voyage types
export const VOYAGE_TYPE = {
  OCEAN: 'ocean',
  RIVER: 'river',
  MIXED: 'river-ocean-combo',
  EXPEDITION: 'expedition',
  MISSISSIPPI: 'mississippi',
};

// ACH types
export const ACH_TYPES = {
  SAVINGS: 'S',
  CHECKING: 'C',
};

export const PAYMENT_METHOD_CHANGE_TYPE = {
  ADD: 'add',
  EDIT: 'edit',
};

export const FOOTER_MODALS = {
  PRIVACY: 'privacyPolicy',
  TERMS: 'termsAndConditions',
  CONTACT: 'contactUs',
};

/* !! IMPORTANT!! Here be :dragon:!
 * if you change the `CALENDAR_ITEM_PIXELS_PER_HOUR` value below, you MUST also
 * change the value of `height` property of `.calendar-line` in CalendarLine.scss!
 * These values must be kept in sync, otherwise the positioning
 * of events on the calendar will be incorrect. :scream:
 */
export const CALENDAR_ITEM_PIXELS_PER_HOUR = 72;

export const DINING_MAX_TABLE_SIZE = 6;

export const SHOREX_GAP_MINUTES = 30;

export const SHIP_RESTAURANT_CODES = {
  MANFREDIS: 'ITL',
  CHEFS_TABLE: 'CHE',
  THE_RESTAURANT: 'RES',
};

export const RESTAURANT_NAMES = {
  [SHIP_RESTAURANT_CODES.CHEFS_TABLE]: "The Chef's Table",
};

export const UTM_PAGE_TYPES = {
  home: [
    APP_PATHS.INDEX,
    VOYAGE_STATUSES.SAILING,
    VOYAGE_STATUSES.SAILED,
    VOYAGE_STATUSES.CANCELED,
    APP_PATHS.LOGIN,
    APP_PATHS.FORGOT_PASSWORD,
    APP_PATHS.FORGOT_PASSWORD_SENT,
    APP_PATHS.FORGOT_PASSWORD_EXPIRED,
    APP_PATHS.FORGOT_EMAIL,
    APP_PATHS.FORGOT_EMAIL_RECOVERED,
    APP_PATHS.SIGN_UP,
    APP_PATHS.SIGN_OUT,
    APP_PATHS.VERIFY_EMAIL,
    APP_PATHS.ACCOUNT_CREATED,
    APP_PATHS.CREATE_ACCOUNT,
    APP_PATHS.EMAIL_EXPIRED,
    APP_PATHS.RESET_PASSWORD,
  ],
  resources: [APP_PATHS.HELP, APP_PATHS.ACCOUNT],
  itinerary: [APP_PATHS.ITINERARY],
  booking_summary: [APP_PATHS.TRAVEL_BOOKING],
  payments: [APP_PATHS.PAYMENTS],
  hotel_extensions: [APP_PATHS.EXTENSIONS],
  shore_excursions: [APP_PATHS.SHORE_EXCURSIONS],
  onboard_experience: [APP_PATHS.ONBOARD_EXPERIENCE],
  air: [APP_PATHS.AIR],
  documents: [APP_PATHS.DOCUMENTS],
  before_you_go: [APP_PATHS.BEFORE_YOU_GO],
  maintenance: [APP_PATHS.MAINTENANCE],
  air_home: [AIR_PATHS.HOME],
  air_checkout: [AIR_PATHS.CHECKOUT],
  air_done: [AIR_PATHS.DONE],
  air_review: [AIR_PATHS.REVIEW],
  air_search: [AIR_PATHS.SEARCH],
  air_seats: [AIR_PATHS.SEATS],
  air_select_passengers: [AIR_PATHS.SELECT_PASSENGERS],
};

export const UTM_SITE_CODE = 'mvj';
export const UTM_COUNTRY_CODE = 'us';
export const UTM_EVENT_NAMES = {
  ACCOUNT_CREATION: 'account_creation',
  ACCOUNT_VERIFIED: 'account_verified',
  LOGIN: 'login',
};

export const UTM_PAGE_TYPE_DEFAULT = 'unspecified';

export const CART_ITEMS = {
  ITEM: 'item',
  ITEMS: 'items',
};

export const FLAG_NAMES = {
  SIGN_UP: 'sign-up',
  MIGRATION: 'migration',
  COMFORT_CHECK_IN_OCEAN: 'comfortCheckIn_ocean',
  COMFORT_CHECK_IN_RIVER: 'comfortCheckIn_river',
  COMFORT_CHECK_IN_EXPEDITION: 'comfortCheckIn_expedition',
  COMFORT_CHECK_IN_COMBO: 'comfortCheckIn_combo',
  CRUISE_INFO: 'cruise-info',
  FILTER_LANDING_DAYS: 'filterLandingDays',
};

export const LOCK_TYPES = {
  LOCK: 'lock',
  UNLOCK: 'unlock',
  REFRESH: 'refresh',
};

export const FEATURE_RESTRICTED = {
  CLOSE_TO_SAILING: 'closeToSailing',
  VIEW_ONLY: 'viewOnly',
};

export const DAYS_TO_GO = {
  DEFAULT: 7,
  ONBOARD_CREDIT_CARD: 3,
};

export const FLAGS = [
  {
    name: FLAG_NAMES.MIGRATION,
    isActive: env.REACT_APP_MIGRATION === 'true',
  },
  {
    name: FLAG_NAMES.SIGN_UP,
    isActive: env.REACT_APP_SIGN_UP === 'true',
  },
  {
    name: FLAG_NAMES.COMFORT_CHECK_IN_OCEAN,
    isActive: env.REACT_APP_COMFORT_CHECK_IN_OCEAN === 'true',
  },
  {
    name: FLAG_NAMES.COMFORT_CHECK_IN_RIVER,
    isActive: env.REACT_APP_COMFORT_CHECK_IN_RIVER === 'true',
  },
  {
    name: FLAG_NAMES.COMFORT_CHECK_IN_EXPEDITION,
    isActive: env.REACT_APP_COMFORT_CHECK_IN_EXPEDITION === 'true',
  },
  {
    name: FLAG_NAMES.COMFORT_CHECK_IN_COMBO,
    isActive: env.REACT_APP_COMFORT_CHECK_IN_COMBO === 'true',
  },
  {
    name: FLAG_NAMES.CRUISE_INFO,
    isActive: env?.REACT_APP_CRUISE_INFO === 'true' || false,
  },
];

export const MVJ_FLAG_VARIABLES = {
  ACTIVITIES_OF_INTEREST_CLOSE_DATE: 'activitiesOfInterestCloseDate',
  ACTIVITIES_OF_INTEREST_FLAG: 'activitiesSurvey',
  BEFORE_YOU_GO_MISSISSIPPI_CARDS: 'beforeYouGoMississippiCards',
  BND_HEALTH_SURVEY: 'bndHealthSurvey',
  BOOKING_PAYMENTS_TAB_FLAG: 'defaultBookingPaymentsTab',
  CCI_CLOSE_HOURS: 'cciCloseHours',
  CCI_VOYAGE_IDS: 'cciVoyageIds',
  CCI_EMAIL_ENABLED: 'cciEmailEnabled',
  ENABLE_INTERACTIVE_CHAT: 'enableInteractiveChat',
  FIT_TO_TRAVEL_CLOSE_DATE: 'fitToTravelCloseDate',
  FIT_TO_TRAVEL_FLAG: 'fitToTravel',
  HEALTH_QUESTIONNAIRE_ID: 'healthQuestionnaireId',
  HEALTH_SURVEY_CLOSE_DATE: 'healthSurveyCloseDate',
  HEALTH_SURVEY_OPEN_DATE: 'healthSurveyOpenDate',
  PPG_AMENITY_GIFT_CODES: 'prePaidGratuityGiftCodes',
  SSBP_AMENITY_GIFT_CODE: 'ssbpGiftCode',
  PAYMENT_DISABLED_COUNTRIES: 'paymentDisabledCountries',
  BLOCK_UK_BOOKING_PAYMENT: 'blockUKBookingPayment',
  BLOCK_UK_CART_PAYMENT: 'blockUKCartPayment',
  BYPASS_AGE_RESTRICTION_IDS: 'bypassAgeRestrictionVoyageIds',
};

export const COUNTRY_FLAGS = {
  HAS_STATES_PROVINCES: 'hasStatesProvinces',
  IS_GDPR: 'isGDPR',
  USE_NEUVEI_SAFECHARGE: 'paymentUseNeuveiSafecharge',
  ALLOW_SCHEDULED_PAYMENTS: 'allowScheduledPayments',
};

export const COUNTRY_CONSTANTS = {
  LONG_DATE_FORMAT: 'longDateFormat',
  LONG_LONTH_DAY: 'longMonthDay',
  PHONE_FORMAT: 'phoneFormat',
  PHONE_MIN_LENGTH: 'phoneMinLength',
  PHONE_REGEX: 'phoneRegEx',
  POSTAL_REGEX: 'postalRegEx',
  SHORT_DATE_FORMAT: 'shortDateFormat',
  SHORT_MONTH_DAY: 'shortMonthDay',
  STATE_PROVINCE_LABEL_KEY: 'stateProvinceLabelKey',
  TIME_ZONE: 'timeZone',
  ZIP_POSTAL_CODE_LABEL_KEY: 'zipPostalCodeLabelKey',
};

// This is also in MVJ props, but this particular constant is utilized
// before common content is loaded
export const EVO_ACTIVITIES_SURVEY_CODE = 'EXPGROUP';

export const ALLOW_EXPEDITIONS = !!(env.REACT_APP_ALLOW_EXPEDITION_CRUISES === 'true');
export const ALLOW_MISSISSIPPI = !!(env.REACT_APP_ALLOW_MISSISSIPPI_CRUISES === 'true');

// Page names and tab names used for reference in the mvjProperties file for routing
export const PAGE_NAMES = {
  HOME: 'home',
  CALENDAR: 'calendar',
  CHECKOUT_CART: 'checkoutCart',
  BOOKING_SUMMARY: 'bookingSummary',
  PAYMENTS_CART: 'paymentsCart',
  EXTENSIONS: 'extensions',
  SHORE_EXCURSIONS: 'shoreExcursions',
  ONBOARD_EXPERIENCE: 'onboardExperience',
  AIR: 'air',
  NEW_AIR: 'newAir',
  GUEST_INFORMATION: 'documents',
  BEFORE_YOU_GO: 'beforeYouGo',
  NOTIFICATIONS: 'notifications',
  HELP: 'help',
  LOGIN_FAQS: 'loginFaqs',
  ACCOUNT: 'account',
  MAINTENANCE: 'maintenance',
  PTC: 'passengerTicketContract',
  SCHEDULE_PAYMENT: 'schedulePayment',
  SUBMARINE_VIDEO: 'submarineVideo',
};

export const TAB_NAMES = {
  // Account
  PROFILE: 'profile',
  REQUEST_INVOICE: 'requestInvoice',
  // Calendar
  LIST: 'list',
  // Booking Summary,
  BOOKING_SUMMARY: 'bookingSummary',
  // Payments and Cart
  ADDONS: 'addons',
  CRUISE_PAYMENTS: 'cruisePayments',
  PAYMENT_METHODS: 'paymentMethods',
  ONBOARD_CREDIT_CARD: 'onboardCreditCard',
  // Extensions
  // Shore Excursions
  // Onboard Experiences
  DINING_BEVERAGE: 'diningBeverage',
  ENRICHMENT: 'enrichment',
  ONBOARD_PREFERENCES: 'preferences',
  SPA: 'spa',
  SHIP_CREW: 'shipCrew',
  STATEROOM: 'stateroom',
  // Onboard Experience Preferences
  FOOD_ALLERGIES: 'allergies',
  STATEROOM_PREFERENCES: 'stateroomPreferences',
  SPECIAL_OCCASIONS: 'specialOccasions',
  SPECIAL_OCCASIONS_TITLE: 'Special Occasions',
  // Air
  AIR_BOOKING: 'airBooking',
  AIR_PREFERENCES: 'preferences',
  TRANSFERS: 'transfers',
  // Guest Information
  GUEST_INFORMATION: 'guestInformation',
  PASSPORTS_VISAS: 'passportsVisas',
  COMFORT_CHECK_IN: 'comfortCheckIn',
  HEALTH_SURVEY: 'healthSurvey',
  FIT_TO_TRAVEL: 'fitToTravel',
  ACTIVITIES_OF_INTEREST: 'activitiesOfInterest',
  // Before you Go
};

export const PHONE_NUMBERS = [
  {
    number: '1-855-706-2326',
    countryName: '(US)',
  },
  {
    number: '1-800-207-7286',
    countryName: '(Canada)',
  },
  {
    number: '10800 319 66 60',
    countryName: '(UK)',
  },
  {
    number: '138 747',
    countryName: '(Australia)',
  },
  {
    number: '0800 447 913',
    countryName: '(New Zealand)',
  },
];

export const UK_SUPPORT_PHONE = '+44 208 8564';

export const SYSTEM_MAINTENANCE_TITLE =
  'My Viking Journey is currently down for maintenance and will be back soon. Please try again later or call for assistance.';

export const OOPS_ERROR_CODES = [504, 401];

export const OOPS_PAGE_ADVISORY_CODES = Object.freeze(['00408', '00802']);

export const AIR_FLOW_NAMES = {
  CHANGE_ITINERARIES: 'change_itineraries',
  CHANGE_SEATS: 'change_seats',
  UPGRADE_CLASS: 'upgrade_class',
};

export const AIR_OFFER_CLASS_TITLE = 'Cabin Class';
export const AIR_CABIN_CLASS = {
  ECONOMY: { text: 'Economy', code: 'economy', evoCode: 'Q' },
  DELTA_COMFORT_PLUS: {
    text: 'Delta Comfort+',
    code: 'delta_comfort_plus',
    evoCode: 'Q',
    textUnformat: 'Delta Comfort Plus',
  },
  PREMIUM_ECONOMY: { text: 'Premium Economy', code: 'premium_economy', evoCode: 'P' },
  BUSINESS: { text: 'Business', code: 'business', evoCode: 'D' },
  FIRST: { text: 'First', code: 'first', evoCode: 'F' },
};

export const STANDARD_FLIGHT_CLASS_MAP = {
  Q: 'ECONOMY',
  P: 'PREMIUM_ECONOMY',
  D: 'BUSINESS',
  F: 'FIRST',
};

export const DELTA_COMFORT_LABEL = 'Delta Comfort+ Available';
export const FLOWS = {
  [AIR_FLOW_NAMES.CHANGE_ITINERARIES]: {
    flowTitle: 'AIR BOOKING',
    totalSteps: 5,
  },
  [AIR_FLOW_NAMES.CHANGE_SEATS]: {
    flowTitle: 'AIR BOOKING',
    totalSteps: 1,
  },
  [AIR_FLOW_NAMES.UPGRADE_CLASS]: {
    flowTitle: 'AIR BOOKING',
    totalSteps: 4,
  },
};

export const AIR_PAGE_ANALYTICS_BASE_DATA = {
  [AIR_FLOW_NAMES.CHANGE_SEATS]: {
    total_steps: '1',
    pages: {
      [AIR_PATHS.SEATS]: { air_step: '1', page_name: 'Seat Map' },
      [AIR_PATHS.DONE]: { air_step: '2', page_name: 'Done Page' },
    },
  },
  [AIR_FLOW_NAMES.UPGRADE_CLASS]: {
    total_steps: '4',
    pages: {
      [AIR_PATHS.SELECT_PASSENGERS]: { air_step: '1', page_name: 'Select Passengers' },
      [AIR_PATHS.SEATS]: { air_step: '2', page_name: 'Seat Map' },
      [AIR_PATHS.REVIEW]: { air_step: '3', page_name: 'Review Page' },
      [AIR_PATHS.CHECKOUT]: { air_step: '4', page_name: 'Checkout Page' },
      [AIR_PATHS.DONE]: { air_step: '5', page_name: 'Done Page' },
    },
  },
  [AIR_FLOW_NAMES.CHANGE_ITINERARIES]: {
    total_steps: '5',
    pages: {
      [AIR_PATHS.SELECT_PASSENGERS]: { air_step: '0', page_name: 'Select Passengers' },
      [AIR_PATHS.SEARCH]: { air_step: '1', page_name: 'Search Page' },
      [AIR_PATHS.SEATS]: { air_step: '3', page_name: 'Seat Map' },
      [AIR_PATHS.REVIEW]: { air_step: '4', page_name: 'Review Page' },
      [AIR_PATHS.CHECKOUT]: { air_step: '5', page_name: 'Checkout Page' },
      [AIR_PATHS.DONE]: { air_step: '6', page_name: 'Done Page' },
    },
  },
};

export const NEW_AIR_SEARCH_SORT_BY = [
  {
    label: 'Recommended',
    value: 'recommended',
  },
  {
    label: 'Price - Lowest first',
    value: 'price-lowest-first',
  },
  {
    label: 'Stops - nonstop first',
    value: 'stops-nonstop-first',
  },
  {
    label: 'Departing Time',
    value: 'departing-timet',
  },
  {
    label: 'Arrival Time',
    value: 'arrival-time',
  },
  {
    label: 'Total Travel Time',
    value: 'total-travel-time',
  },
];
export const PAGES_PROGRESS_INDICATOR = [
  {
    id: 1,
    text: 'Select Flights',
    disabled: false,
    selected: false,
  },
  {
    id: 2,
    text: 'Select Seats',
    disabled: false,
    selected: false,
  },
  {
    id: 3,
    text: 'Review',
    disabled: false,
    selected: false,
  },
  {
    id: 4,
    text: 'Confirm',
    disabled: false,
    selected: false,
  },
];

export const FLIGHT_SELECTOR_MESSAGES = {
  notFound: 'No Viking supported airports found. Please try searching for another airport.',
  suggestion: 'Here are airports found near',
  noMatch: 'No Viking supported airports found. Here are suggested airports near',
};

export const AIR_ADD_COLLECT = {
  FLIGHT: 'flight',
  TRAVEL_PROTECTION: 'travelProtection',
  TRAVEL_PROTECTION_TOTAL: 'travelProtectionTotal',
  STOPOVER: 'stopover',
  DEVIATION: 'deviation',
  GATEWAY_CHANGE: 'gatewayChange',
  UPGRADE: 'upgrade',
  ACH_DISCOUNT: 'achDiscount',
  PAID_SEATS: 'paidSeats',
  AIR_COST: 'airCost',
  TOTAL: 'total',
};

export const HEADER_MENUS = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const AIR_PAYMENT_CHECKOUT_CODES = {
  offerNotFound: 'OFFER_NOT_FOUND',
  itineraryNotAvailable: 'ITINERARY_IS_NOT_AVAILABLE',
  bookingNotFound: 'BOOKING_NOT_FOUND',
  badRequest: 'BAD_REQUEST',
  repriced: 'REPRICED',
  valid: 'VALID',
  invalid: 'INVALID',
};

export const CART_COUNT_INPUTS = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
};

export const CREDIT_CARD_TYPE = {
  VI: {
    code: 'VI',
    tokenExCode: 'visa',
    iconName: 'visa',
    isOnboard: true,
    cardType: 'Visa',
    label: 'Visa',
  },
  MC: {
    code: 'MC',
    tokenExCode: 'masterCard',
    iconName: 'mastercard',
    isOnboard: false,
    cardType: 'Master',
    label: 'Master Card',
  },
  DI: {
    code: 'DI',
    tokenExCode: 'discover',
    iconName: 'discover',
    isOnboard: false,
    cardType: 'Discover',
    label: 'Discover',
  },
  AX: {
    code: 'AX',
    tokenExCode: 'americanExpress',
    iconName: 'american-express',
    isOnboard: true,
    cardType: 'Amex',
    label: 'American Express',
  },
  UNKNOWN: {
    code: '',
    tokenExCode: 'unknown',
    iconName: 'unknown-card',
    isOnboard: false,
    label: '',
  },
};

export const CARD_TYPE_REPLACE_TOKEN = '[CARD_TYPES]';

export const PAYMENT_CHALLENGE_COUNTDOWN_SECONDS = 5;

export const PAYMENT_CHALLENGE_TIMEOUT_SECONDS = 180;

export const PAYMENT_STEPS = {
  INIT: 'INITIAL',
  CHALLENGE: 'CHALLENGE',
  POST_CHALLENGE: 'POST-CHALLENGE',
  FINAL: 'FINAL',
  CANCELED: 'CANCEL',
  TIMEOUT: 'TIMEOUT',
};

export const SCHEDULED_PAYMENT_STATE = Object.freeze({
  ALLOWED: 'allow',
  DISABLED: 'disabled',
  HIDDEN: 'hidden',
});

export const CHALLENGE_CLOSE_TRIGGER = Object.freeze({
  CANCEL: 'cancel',
  SUCCESS: 'success',
});

export const CHALLENGE_MERCHANT_URL = 'https://www.viking.com';

export const PSD2_PAYMENT_COUNTRIES = Object.freeze(['UK', 'GB']);

const LOGO_BASE_URL = 'https://abe-airline-logos.viking.com';
export const MULTIPLE_AIRLINES = {
  displayName: 'Multiple Airlines',
  logoURL: `${LOGO_BASE_URL}/svg/MultiAirlineLogo.svg`,
};

export const UNKNOWN_AIRLINE = {
  logoURL: `${LOGO_BASE_URL}/svg/NoAirlineLogo.svg`,
};

export const BAGGAGE_POLICY_URL = 'https://vikingcruises.com/luggage-restrictions';

export const CHANGE_PASSWORD_URL = 'https://www.myvikingjourney.com/home/welcome/#resetpassword';

export const RESPONSE_STATUS = {
  SUCCESS_200: 200,
  SUCCESS_CREATED_201: 201,
  SUCCESS_NO_CONTENT_204: 204,
  SUCCESS_PARTIAL_CONTENT_206: 206,
  FAILURE_TIMEOUT_408: 408,
  FAILURE_ERROR_CODE: 400,
  FAILURE_NOT_FOUND_404: 404,
  FAILURE_ERROR_CODE_424: 424,
  FAILURE_ERROR_CODE_500: 500,
  FAILURE_INTERNAL_CODE: 'INVALID_REQUEST',
  FAILURE_NOT_FOUND: 'NOT_FOUND',
  FAILURE_NO_PNR: 'BOOKING_NOT_FOUND',
  FAILURE_LOCKED: '9006',
};

export const SUCCESS_STATUS_CODES = [
  RESPONSE_STATUS.SUCCESS_200,
  RESPONSE_STATUS.SUCCESS_CREATED_201,
  RESPONSE_STATUS.SUCCESS_NO_CONTENT_204,
  RESPONSE_STATUS.SUCCESS_PARTIAL_CONTENT_206,
];

export const POLLING_TIMES = {
  REPEAT_INTERVAL: 2000,
  TWO_MIN_TIME_OUT: 120000,
  ONE_MIN_TIME_OUT: 60000,
};

export const CANCELED_INTERVAL_ID = -1;

export const AIR_EXTENSION = {
  DEVIATION: '996',
  PAID_SEATS: '979',
  DIFFERENTIAL: '999',
};

export const RESERVATION_NUMBERS = {
  US: '1-877-523-0580',
  UK: '0208 780 8714',
  AU: '138 747',
  NZ: '0800 447 913',
};

export const GEORGIAN_BAY_SHOREX = 'Explore Georgian Bay';

export const GREAT_LAKES_CRUISES = ['Great Lakes Explorer', 'Great Lakes Collection'];

export const COMFORT_CHECK_IN_STEPS = {
  PERSONAL_INFO: 'personalInfo',
  CRUISE_INFO: 'cruiseInfo',
  ONBOARD_CC_INFO: 'onBoardCC',
};

export const TOTAL_SEARCH_RESULTS = {
  SINGLE: 'Result',
  MULTIPLE: 'Results',
};

// NOTE: These correspond to codes in MT
export const VIEWERSHIP_STATUSES = {
  AIR_PENDING: 'AIR_PENDING',
  BALANCE_DUE: 'BALANCE_DUE',
  CRUISE_ONLY_NO_GIF: 'CRUISE_ONLY_NO_GIF',
  CURRENTLY_UNAVAILABLE: 'CURRENTLY_UNAVAILABLE',
  FLIGHT_ERROR: 'FLIGHT_ERROR',
  FLIGHT_SCHEDULE_READONLY: 'FLIGHT_SCHEDULE_READONLY',
  GIF_OVERRIDE: 'GIF_OVERRIDE',
  HAS_PAID_SEATS: 'HAS_PAID_SEATS',
  IS_DELTA_COMFORT_PLUS: 'IS_DELTA_COMFORT_PLUS',
  NO_AIR_NO_PAYMENT: 'NO_AIR_NO_PAYMENT',
  NO_AIR_PAX: 'NO_AIR_PAX',
  NO_PAX_FLIGHTS: 'NO_PAX_FLIGHTS',
  NO_GIF: 'NO_GIF',
  NO_PAYMENT: 'NO_PAYMENT',
  NO_VIKING_AIR: 'NO_VIKING_AIR',
  READ_ONLY: 'READ_ONLY',
  RIGHT_REZ: 'RIGHT_REZ',
  SCHED_CHANGE: 'SCHED_CHANGE',
  TICKETED: 'TICKETED',
};

export const EMAIL_TEMPLATE_IDS = {
  BOARDING_PASS: 'boardingPassEmail',
  BOARDING_PASS_MSP: 'boardingPassEmailMSP',
};

export const FLIGHT_SUMMARY = {
  FROM: 'From',
  TO: 'To',
};

export const SEATS = {
  OPERATED_BY: 'Operated by',
  PENDING: 'Pending',
  SEAT: 'Seat',
  PAID_SEAT: 'Paid Seat',
  UNAVAILABLE: 'Unavailable',
  UNSELECTED: 'Unselected',
};

export const PATIENT_TYPES = {
  GUEST: 'GUEST',
};

export const NO_DATA_PAGE = {
  HOME_MESSAGE: 'Something went wrong while fetching your itinerary. Please try again later.',
  HOME_CALL_TO_ACTION: 'MVJ HOME',
  SEARCH_MESSAGE: 'Something went wrong while fetching the data. Please try again later.',
  SEARCH_CALL_TO_ACTION: 'AIR HOME',
};

export const NAVIGATION_TYPES = {
  NAVIGATE: 0,
  RELOAD: 1,
  BACK_FORWARD: 2,
  OTHER: 255,
};

export const LEGEND = {
  TITLE: 'Legend',
  SELECTED: 'Selected',
  AVAILABLE: 'Available',
  AVAILABLE_FREE: 'Available Free',
  UNAVAILABLE: 'Unavailable',
  EXIT: 'Exit',
  PAID_SEAT: 'Available Paid',
  PAID_SEAT_TEXT: 'Paid Seat',
  TITLE_OPEN: 'Close Legend',
  TITLE_CLOSE: 'View Legend',
};

export const FILTER_BOX_DIFF = {
  PRICE: 100,
  CONNECTION_TIME: 0.25,
  DEPARTURE_TIME: 0.25,
};

export const FILTER_BOX_ANY_TEXT = {
  DEPARTURE_TIME: 'Any Departure Time',
  MAXIMUM: 'maximum',
  MINIMUM: 'minimum',
  TO_TEXT: 'to',
  OUTBOUND_TEXT: 'Outbound',
  RETURN_TEXT: 'Return',
  OUTBOUND_STOPOVER_TEXT: 'Outbound Stopover',
  RETURN_STOPOVER_TEXT: 'Return Stopover',
};

export const CHECKOUT_STEP_PROGRESS = {
  START_PERCENTAGE: 0,
  STEP_1_PERCENTAGE: 33,
  STEP_2_PERCENTAGE: 66,
  STEP_3_PERCENTAGE: 99,
  COMPLETE_PERCENTAGE: 100,
};
export const CHECKOUT_TOTAL_TIME = 240;

export const SEATMAP_TIMEOUT_CALL_MAX = 1;
export const CHECKOUT_PROGRESS_STEPS = [
  {
    time: CHECKOUT_TOTAL_TIME / 4,
    start: CHECKOUT_STEP_PROGRESS.START_PERCENTAGE,
    end: CHECKOUT_STEP_PROGRESS.STEP_1_PERCENTAGE - 1,
  },
  {
    time: CHECKOUT_TOTAL_TIME / 4,
    start: CHECKOUT_STEP_PROGRESS.STEP_1_PERCENTAGE,
    end: CHECKOUT_STEP_PROGRESS.STEP_2_PERCENTAGE - 1,
  },
  {
    time: CHECKOUT_TOTAL_TIME / 4,
    start: CHECKOUT_STEP_PROGRESS.STEP_2_PERCENTAGE,
    end: CHECKOUT_STEP_PROGRESS.STEP_3_PERCENTAGE - 1,
  },
  {
    time: CHECKOUT_TOTAL_TIME / 4,
    start: CHECKOUT_STEP_PROGRESS.STEP_3_PERCENTAGE,
    end: CHECKOUT_STEP_PROGRESS.COMPLETE_PERCENTAGE,
  },
];

export const UPDATESEATS_STEP_PROGRESS = {
  START_PERCENTAGE: 0,
  STEP_1_PERCENTAGE: 33,
  STEP_2_PERCENTAGE: 66,
  STEP_3_PERCENTAGE: 99,
  COMPLETE_PERCENTAGE: 100,
};
export const UPDATESEATS_TOTAL_TIME = 120;

export const UPDATESEATS_PROGRESS_STEPS = [
  {
    time: UPDATESEATS_TOTAL_TIME / 4,
    start: UPDATESEATS_STEP_PROGRESS.START_PERCENTAGE,
    end: UPDATESEATS_STEP_PROGRESS.STEP_1_PERCENTAGE - 1,
  },
  {
    time: UPDATESEATS_TOTAL_TIME / 4,
    start: UPDATESEATS_STEP_PROGRESS.STEP_1_PERCENTAGE,
    end: UPDATESEATS_STEP_PROGRESS.STEP_2_PERCENTAGE - 1,
  },
  {
    time: UPDATESEATS_TOTAL_TIME / 4,
    start: UPDATESEATS_STEP_PROGRESS.STEP_2_PERCENTAGE,
    end: UPDATESEATS_STEP_PROGRESS.STEP_3_PERCENTAGE - 1,
  },
  {
    time: UPDATESEATS_TOTAL_TIME / 4,
    start: UPDATESEATS_STEP_PROGRESS.STEP_3_PERCENTAGE,
    end: UPDATESEATS_STEP_PROGRESS.COMPLETE_PERCENTAGE,
  },
];

export const UPSELL_BAR_TILE = {
  ADD_DINING: 'upsellBarAddDiningTile',
  ADD_EXTENSION: 'upsellBarAddExtensionTile',
  ADD_SHORE_EXCURSION: 'upsellBarAddShoreExcursionsTile',
};

export const CRUISE_EXCEPTIONS_UK = ['OVE210522', 'OVE210529', 'OVE210605', 'OVE210614'];

export const AIR_START_OVER_MODAL = {
  message: 'Unsaved changes will be lost.<br />\nAre you sure you want to search for a different flight itinerary?',
  confirm: 'YES, START OVER',
  cancel: 'CANCEL',
};

export const AIR_LEAVE_MODAL = {
  message: 'Unsaved changes will be lost.<br />\nAre you sure you want to leave this page?',
  confirm: 'YES, LEAVE',
  cancel: 'CANCEL',
};

export const KEYCODES = {
  ENTER: 'Enter',
  SPACEBAR: 'Spacebar',
  TAB: 'Tab',
};

export const SEATMAP_SCROLL_SIZE = 300;
export const SEATMAP_LABEL_ROW_SIZE = 30;
export const SEATMAP_LABEL_ROW_AVERAGE = 3;

export const BEFORE_YOU_GO_DISCLAIMER_VOYAGES = Object.freeze([
  'OVE210522',
  'OVE210529',
  'OVE210605',
  'OVE210619',
  'OVE210626',
  'OOR210615',
  'OOR210622',
  'OOR210629',
  'OOR210706',
  'OOR210713',
  'OOR210720',
  'OOR210727',
  'OOR210803',
  'OSK210626',
  'OSE210711',
  'OSE210711A',
  'OSE210721',
  'OSE210721A',
  'OSE210731',
  'OSE210731A',
  'OSE210810',
  'OSE210810A',
  'OSE210820',
  'OSE210820A',
  'OSE210830',
  'OSE210830A',
  'OSE210909',
  'OSE210909A',
  'OSE210919',
  'OVE210714',
  'OVE210724',
  'OVE210803',
  'OVE210813',
  'OVE210823',
  'OVE210902',
  'OVE210912',
  'OVE210922',
  'OVE211002',
  'OVE211012',
]);

export const PRE_TRAVEL_MISSISSIPPI_CARDS = Object.freeze({
  US: { cards: ['VHS'] },
  CA: { cards: ['VHS'] },
  UK: { cards: ['VHS'] },
  AU: { cards: ['VHS'] },
});

export const HEALTH_SURVEY_QUESTION_TYPES = {
  YN: 'YN',
  DATE: 'DATE',
  SINGLE_SELECTION: 'SINGLE_SELECTION',
};

export const DEVIATION_TYPES = {
  DATE: 'DATE',
  CITY: 'CITY',
  NONE: 'NONE',
};
export const ACTIVITIES_OF_INTEREST_CODES = Object.freeze(['ATA', 'SJM']);

export const ACTIVITIES_SURVEY_VARIABLES = Object.freeze({
  MARKET_CODES: ['ATA', 'SJM'],
  SURVEY_CODE: 'SV_egEPF4dAU0FoIn4',
  IFRAME_URL: 'https://iad1.qualtrics.com/jfe/form',
});

export const EU_COUNTRY_CODES = Object.freeze(
  new Set([
    // -----[ EU 28 ]-----
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'FR', // France
    'DE', // Germany
    'GR', // Greece
    'HU', // Hungary
    'IE', // Ireland, Republic of (EIRE)
    'IT', // Italy
    'LV', // Latvia
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'NL', // Netherlands
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'GB', // United Kingdom (Great Britain)

    // -----[ Outermost Regions (OMR) ]------
    'GF', // French Guiana
    'GP', // Guadeloupe
    'MQ', // Martinique
    'ME', // Montenegro
    'YT', // Mayotte
    'RE', // Réunion
    'MF', // Saint Martin

    // -----[ Special Cases: Part of EU ]-----
    'GI', // Gibraltar
    'AX', // Åland Islands

    // -----[ Overseas Countries and Territories (OCT) ]-----
    'PM', // Saint Pierre and Miquelon
    'GL', // Greenland
    'BL', // Saint Bartelemey
    'SX', // Sint Maarten
    'AW', // Aruba
    'CW', // Curacao
    'WF', // Wallis and Futuna
    'PF', // French Polynesia
    'NC', // New Caledonia
    'TF', // French Southern Territories
    'AI', // Anguilla
    'BM', // Bermuda
    'IO', // British Indian Ocean Territory
    'VG', // Virgin Islands, British
    'KY', // Cayman Islands
    'FK', // Falkland Islands (Malvinas)
    'MS', // Montserrat
    'PN', // Pitcairn
    'SH', // Saint Helena
    'GS', // South Georgia and the South Sandwich Islands
    'TC', // Turks and Caicos Islands

    // -----[ Microstates ]-----
    'AD', // Andorra
    'LI', // Liechtenstein
    'MC', // Monaco
    'SM', // San Marino
    'VA', // Vatican City

    // -----[ Other ]-----
    'JE', // Jersey
    'GG', // Guernsey
  ])
);

export const MODAL_URL_VARIABLE_NAMES = {
  BYG_CODE: 'bygCode',
  EXTENSION_CODE: 'extensionCode',
  GIF_CODE: 'gifCode',
  ONBOARD_CODE: 'onboardCode',
  SHORE_EX_CODE: 'shoreExCode',
};

export const DRIVERS_LICENSE_REGEX = /[a-zA-Z0-9*]/;

export const AIR_LABELS = {
  BOARDING_PASS: 'Boarding Pass',
  HOTEL: 'Hotel',
  HOTEL_STOPOVER: 'Hotel Stopover',
  OUTBOUND: 'Outbound',
  RETURN: 'Return',
  STOPOVER: 'Stopover',
};

export const ECONOMY_DELTA_FORM = 'deltaEconomyFrom';
export const PAX_IDX = {
  PAX_1: 0,
  PAX_2: 1,
};

export const PAX_IDS_STRING = {
  PAX_1: '1',
  PAX_2: '2',
};

export const FROM_PAGE = {
  THANK_YOU: 'thankYouPage',
};

export const CHAT_HOVER_TEXT = 'Questions? A Viking agent is here to help.';
export const CHAT_BUTTON_POLL_TIME = 5000;

export const THANK_YOU_BUTTONS = {
  MANAGE_SEATS: 'MANAGE YOUR SEATS',
  AIR_PREFERENCES: 'AIR PREFERENCES',
  VIEW_ITINERARY: 'VIEW ITINERARY',
};

export const PRINT_BUTTON_TEXT = 'PRINT ITINERARY';

export const PRINT = 'PRINT';

export const LOGIN_FAQS_HEADER = 'GUEST ACCOUNT FAQS';

export const ECONOMY_DELTA_BOX = {
  INCHES: ' inches',
};

export const PAYMENT_CHALLENGE_IFRAME_SIZES = Object.freeze({
  XS: {
    width: '250px',
    height: '400px',
    challengeWindowSize: '01',
  },
  SM: {
    width: '390px',
    height: '400px',
    challengeWindowSize: '02',
  },
  MD: {
    width: '500px',
    height: '600px',
    challengeWindowSize: '03',
  },
  LG: {
    width: '600px',
    height: '400px',
    challengeWindowSize: '04',
  },
  FULL: {
    width: '100%',
    height: '100%',
    challengeWindowSize: '05',
  },
});

export const GREAT_LAKES_VOYAGES = Object.freeze([
  // 'XOC220326',
  // 'XOC220326A',
]);
export const SUB_DIV_SHOREX_CHARGE = 'CHARGED ON BOARD';
export const SUB_DIV_SHOREX = Object.freeze([
  'AQSUB',
  'VXPCAFZ007',
  'VXPCAKLY07',
  'VXPCALOU18',
  'VXPCALUN18',
  'VXPCAPRS10',
  'VXPCASI107',
  'VXPCATHU14',
  'VXPCAYHZ18',
  'VXPCLG0104',
  'VXPCLG0204',
  'VXPCLG0305',
  'VXPCLG0404',
  'VXPCLTRL04',
  'VXPKNSKB06',
  'VXPLCSOF21',
  'VXPMSG02',
  'VXPVGVIR07',
  'VXPSUBBRIE',
]);

export const PAYMENT_CART_ID = {
  PAYMENT_CART_CARD: '37fd4f47-e62f-4b1e-8edb-3540c074c795',
  NAVIGATION_MENU: '31bf3bd9-82dd-4f76-8db8-9953ca89dae7',
};

export const CART_PRICE_UPDATE_MODAL = {
  ID: 'cartPriceChangeModal',
  BUTTON_TEXT: 'CONTINUE TO CART',
};

export const CART_PRICE_TIME_UPDATE_MODAL = {
  ID: 'cartPriceTimeChangeModal',
};

export const CART_TIME_UPDATE_MODAL = {
  ID: 'cartTimeChangeModal',
  BUTTON_MODIFY_TEXT: 'MODIFY/REMOVE',
};
export const PROACTIVE_CHAT_TIMER = 1000;

export const OOPS_PAGE_ERRORS = Object.freeze({
  AEM_ENDPOINT_DOWN: 'AEM_ENDPOINT_DOWN',
  ALREADY_BOOKED: 'ALREADY_BOOKED',
  CALENDAR_REBUILDING: 'CALENDAR_REBUILDING',
  DUPLICATE_CART: 'DUPLICATE_CART',
  // NAME_UPDATE_ERROR: 'NAME_UPDATE_ERROR',
  DEFAULT: 'DEFAULT',
});

export const OOPS_PAGE_CONTENT = Object.freeze({
  DUPLICATE_CART: {
    title: 'Unable to load booking details',
    body: ['There is a conflict with this booking information causing the page to not load.'],
  },
  AEM_ENDPOINT_DOWN: {
    title: 'Page Temporarily Unavailable',
    body: ['This page is temporarily unavailable due to maintenance. Please try again later.'],
  },
  ALREADY_BOOKED: {
    title: 'Page Temporarily Unavailable',
    body: [
      'This itinerary is currently not available on My Viking Journey.  We will notify you once you are able to sign in and manage your booking.',
    ],
  },
  CALENDAR_REBUILDING: {
    title: 'Page Temporarily Unavailable',
    body: [
      'This page is taking longer than expected to load.',
      'Please try again in a few minutes or navigate to home.',
    ],
  },
  /*
  NAME_UPDATE_ERROR: {
    title: 'TBD: NEEDS A TITLE',
    body: [
      'TBD: Error occured when updating account, please try again later'
    ]
  }
   */
  DEFAULT: {
    title: 'Page Not Found',
    body: ['The page you have encountered is not available.', 'Please try another link or navigate to home.'],
  },
});

export const SURVEY_CODES = {
  SUBMARINE_WAIVER: 'SUBWVR',
};

export const PROMOS = {
  BND_PROMO: 'BNDPROMO',
};

export const PROMO_CODES = {
  MAY_2024: 'FCW5',
};

export const PROMO_URLS = {
  OCEAN: `https://www.vikingcruises.com/oceans/search-cruises/index.html?promoCode=${PROMO_CODES.MAY_2024}`,
  RIVER: `https://www.vikingrivercruises.com/search-cruises/index.html?promoCode=${PROMO_CODES.MAY_2024}`,
};

export const DINING_DETAILS = {
  CHEF_MENU: 'Menu: TBD',
  THE_RESTAURANT: 'The Restaurant',
  READ_MORE: '+ Read more',
  READ_LESS: '- Read less',
  RESERVATIONS_BUTTON: 'RESERVE',
};

export const APP_INSIGHTS = Object.freeze({
  CONNECTION_STRING: env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
  INSTANCE_NAME: env.REACT_APP_APP_INSIGHTS_INSTANCE_NAME,
  NAME: 'mvj-client',
  RESOURCE_GROUP: env.REACT_APP_APP_INSIGHTS_RESOURCE_GROUP,
  SUBSCRIPTION_ID: env.REACT_APP_APP_INSIGHTS_SUBSCRIPTION_ID,
  TENANT_PRIMARY_DOMAIN: env.REACT_APP_APP_INSIGHTS_TENANT_PRIMARY_DOMAIN,
});

export const APP_INSIGHTS_TRACK_TYPE = Object.freeze({
  TRACE: 'TRACE',
  EVENT: 'EVENT',
});

export const LOCK_CODES = Object.freeze({
  LOCK_CODE_01: 'Lock01',
  LOCK_CODE_02: 'Lock02',
  LOCK_CODE_03: 'Lock03',
  LOCK_CODE_04: 'Lock04',
});

export const TIMER_STATUS = {
  INIT: 'INIT',
  START: 'START',
  IN_PROCESS: 'IN_PROCESS',
  COMPLETED: 'COMPLETED',
};

export const CLOSE_TO_SAILING_OVERRIDE_PATHS = Object.freeze([
  APP_PATHS.COMFORT_CHECK_IN,
  APP_PATHS.ONBOARD_CREDIT_CARD,
]);

export const EVO_HEADER = {
  AGENT_EMAIL: 'Evo-Agent-Email',
  AGENT_IATA: 'Evo-Agent-Iata',
  AGENCY_ID: 'Evo-Agency-Id',
  AGENT_NAME: 'Evo-Agent-Name',
  UI_CODE: 'Evo-UI-Code',
  GDS_CODE: 'Evo-GDS-Code',
};

export const ADOBE_MODAL_NAME_MAPPING = Object.freeze({
  notificationModal: 'promo_notification',
});

export const NO_BOOKING_ID = 'no_booking_id';
