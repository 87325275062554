import BannerNotification from '@viking-eng/banner-notification';
import Checkbox from '@viking-eng/checkbox';
import FormActions from '@viking-eng/form-actions';
import PaymentMethodRow from '@viking-eng/payment-method-row';
import TabPage from '@viking-eng/tab-page';
import classnames from 'classnames';
import React from 'react';
import { Form } from 'redux-form';
import { ALERT_TYPES, MODALS, PAYMENT_ACTION_TYPE } from '../../../common/Constants';
import history from '../../../common/history';
import { Field, FormCreditCard } from '../../../components';
import { DialogModal } from '../../../modals';
import PropTypes from '../../../PropTypes';
import './OnBoardCreditCard.scss';

const dialogModalId = MODALS.DIALOG;

class OnBoardCreditCard extends React.Component {
  componentWillUnmount() {
    const { handleUpdateAddCardFlag } = this.props;
    handleUpdateAddCardFlag(false);
  }

  render() {
    const {
      paymentMethods,
      openRemovalModal,
      fields,
      form,
      submitting,
      isSubmitting: isModalSubmitting,
      isLoading,
      content,
      handleSubmit,
      resetReservationForm,
      handleRemoveOnboardCreditCard,
      handleCancelOnboardCCRemove,
      removeOnboardCCModalData,
      isAddingCard,
      handleUpdateAddCardFlag,
      submitFailed,
      valid,
      genericValidationMessage,
      isOnboardCreditCardDateInvalid,
      country,
      disableSaveCardButton,
      restrictPaymentMessage,
      isPaymentsDisabled,
      paymentsDisabledBanner,
    } = this.props;
    const { title, subtitle } = content;
    const errorTipMsg = submitFailed && !valid ? genericValidationMessage : '';
    const alert = {
      message: errorTipMsg || restrictPaymentMessage,
      type: submitFailed || restrictPaymentMessage ? ALERT_TYPES.ERROR : ALERT_TYPES.INFO,
    };
    const {
      mappedPaymentMethods,
      saveCardLabel,
      cancelLabel,
      hasSavedCCPax1,
      hasSavedCCPax2,
      isSinglePax,
      lockedDown,
      lockDownMessage,
    } = paymentMethods;

    let paymentMethod = mappedPaymentMethods;
    if (paymentMethods) {
      paymentMethod = mappedPaymentMethods.map((pm) => ({
        ...pm,
        paymentMethodsValues: {
          ...pm.paymentMethodsValues,
          button: {
            ...pm.paymentMethodsValues.button,
            attributes: {
              onButtonClick: () => handleUpdateAddCardFlag(true),
              disabled: lockedDown,
            },
          },
          title,
        },
        items: pm.items.reduce((acc, item) => {
          if (item) {
            acc.push({
              ...item,
              buttons: item.actionButtons.map((button) => {
                let attributes;
                switch (button.action) {
                  case PAYMENT_ACTION_TYPE.REMOVE:
                    attributes = {
                      onButtonClick: () => openRemovalModal(dialogModalId, item.id),
                      disabled: lockedDown,
                    };
                    break;
                  default:
                    break;
                }

                return {
                  ...button,
                  attributes,
                };
              }),
            });
          }
          return acc;
        }, []),
      }));
    }
    return (
      <div className="onboard-credit-card">
        {isPaymentsDisabled && (
          <div className="row">
            <div className="container banner-wrapper payment-alert-container">
              <BannerNotification
                alert={{
                  alertText: paymentsDisabledBanner,
                }}
                icon="exclamation-mark-filled"
              />
            </div>
          </div>
        )}
        {lockedDown && lockDownMessage && (
          <BannerNotification
            alert={{
              alertText: lockDownMessage,
            }}
            icon="calendar"
            history={history}
          />
        )}
        <TabPage headerText={subtitle} />
        {!isLoading && (
          <div>
            {!isAddingCard && (hasSavedCCPax1 || hasSavedCCPax2) ? (
              <div className="row">
                <div className="col-12">
                  {mappedPaymentMethods &&
                    paymentMethod.map(({ paymentMethodsValues, items }) => (
                      <div
                        className={classnames(
                          'onboard-credit-card',
                          ((hasSavedCCPax1 && hasSavedCCPax2) || isSinglePax) && 'has-saved-cc'
                        )}
                      >
                        <PaymentMethodRow rowHeader={paymentMethodsValues} savedItems={items} isOnboardCC />
                      </div>
                    ))}
                </div>
                <DialogModal
                  id={dialogModalId}
                  onConfirm={handleRemoveOnboardCreditCard}
                  onCancel={handleCancelOnboardCCRemove}
                  forceAction={isModalSubmitting}
                  {...removeOnboardCCModalData}
                />
              </div>
            ) : (
              (isAddingCard || (!hasSavedCCPax1 && !hasSavedCCPax2)) && (
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8 col-12">
                    <Form onSubmit={handleSubmit}>
                      <FormCreditCard
                        {...fields}
                        form={form}
                        isOnboardForm
                        disabled={lockedDown}
                        propCountry={country}
                      />
                      {!isSinglePax && !hasSavedCCPax1 && !hasSavedCCPax2 && (
                        <div className="additional-actions d-flex justify-content-between">
                          <Field component={Checkbox} id="saveBox" label={fields.shareCard} name="save" />
                        </div>
                      )}
                      <FormActions
                        primaryButton={{
                          onButtonClick: null,
                          text: saveCardLabel,
                          loading: submitting,
                          disabled: lockedDown || isOnboardCreditCardDateInvalid || disableSaveCardButton,
                        }}
                        history={history}
                        secondaryButton={{
                          // eslint-disable-next-line max-len
                          onButtonClick: () => resetReservationForm(hasSavedCCPax1, hasSavedCCPax2),
                          text: cancelLabel,
                          disabled: submitting,
                        }}
                        scrollOnAlert
                        alert={alert}
                      />
                    </Form>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  }
}

OnBoardCreditCard.propTypes = {
  paymentMethods: PropTypes.shape({
    rowHeader: PropTypes.paymentMethodRowHeader,
    savedItems: PropTypes.arrayOf(PropTypes.paymentMethodSavedItem),
  }),
  openRemovalModal: PropTypes.func.isRequired,
  removeOnboardCCModalData: PropTypes.shape({
    ...PropTypes.dialogModalFields,
  }),
  fields: PropTypes.shape({
    ...PropTypes.creditCardFields,
  }),
  form: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  content: PropTypes.shape({}),
  handleSubmit: PropTypes.func.isRequired,
  resetReservationForm: PropTypes.func.isRequired,
  handleRemoveOnboardCreditCard: PropTypes.func.isRequired,
  handleCancelOnboardCCRemove: PropTypes.func.isRequired,
  guests: PropTypes.shape({}),
  isAddingCard: PropTypes.bool.isRequired,
  handleUpdateAddCardFlag: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  genericValidationMessage: PropTypes.string.isRequired,
  isOnboardCreditCardDateInvalid: PropTypes.bool,
  country: PropTypes.string.isRequired,
  disableSaveCardButton: PropTypes.bool.isRequired,
  restrictPaymentMessage: PropTypes.string.isRequired,
  isPaymentsDisabled: PropTypes.bool.isRequired,
  paymentsDisabledBanner: PropTypes.string.isRequired,
};

OnBoardCreditCard.defaultProps = {
  paymentMethods: [],
  fields: {},
  content: {},
  removeOnboardCCModalData: {},
  isSubmitting: false,
  guests: {},
  isOnboardCreditCardDateInvalid: false,
};

export default OnBoardCreditCard;
